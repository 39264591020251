import { Link, NavLink } from "react-router-dom";
import type { RootState } from "../../store/index";
import { useSelector } from "react-redux";
import ContactLink from "../UI/ContactLink";
const AsideNav = (props: { showIndex: any }) => {
  const headerList = useSelector((state: RootState) => state.header.headerList);
  return (
    <>
      <div className="asideNav">
        <div className="asideNavTitleContainer">
          <Link to={headerList[props.showIndex].url} className="asideNavTitle">
            <span className="navEnLabel">{headerList[props.showIndex].labelEn}</span>
            <span className="navZhLabel" >{headerList[props.showIndex].label}</span>
          </Link>
          {/* <Link
            to={headerList[props.showIndex].url}
            className="asideNavEnTitle"
          >
            <span>{headerList[props.showIndex].label}</span>
          </Link> */}
        </div>
        <div className="asideNavLabelContainer">
          {headerList[props.showIndex].children.map(
            // isActive 會在點選後 有對應的樣式，當link點選後要有對應的樣式時，需使用NavLink
            (item: any, index: number) => (
              <NavLink key={index} to={item.url} className={({ isActive }) => `asideNavLabel ${isActive && 'active'}`}>
                {item.label}
                <div className="asideNavLabelArrow"></div>
              </NavLink>
            )
          )}
        </div>
        <ContactLink className="asideContactContainer"></ContactLink>
      </div>
    </>
  );
};
export default AsideNav;
