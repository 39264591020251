import  { MouseEventHandler } from 'react';
const SubmitButton = ({ title, onClick, className, type, disabled, ...props }: {
  title: string,
  onClick?: MouseEventHandler,
  className?: string,
  type?: string,
  disabled?: boolean,
  props?: any
}) => {
  return (
    <button
      disabled={disabled}
      className={className}
      onClick={onClick}
      {...props}
    >
      {title}
    </button>
  );
};
export default SubmitButton;
