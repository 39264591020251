import AsideNav from '../../components/mainLayout/AsideNav'
import { Link } from 'react-router-dom'
import Breadcrumbs from "../../components/UI/Breadcrum";
import ContactLink from 'components/UI/ContactLink';
import AboutVisual from 'components/aboutMain/AboutVisual';
import { Helmet } from 'react-helmet-async';

const AboutMain = () => {
  return <>
    <Helmet>
      <title>關於我們｜TIDC台灣國際-智慧停車領航者</title>
    </Helmet>
    <AsideNav showIndex={0}></AsideNav>
    <Breadcrumbs></Breadcrumbs>

    <div className='selfContainer'>
      <span className="breadcrumbs">
        <ol className="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <Link itemProp="item" to="/">
              <span itemProp="name">首頁</span></Link>
            <meta itemProp="position" content="1" />
          </li>
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <span itemProp="name">關於我們</span>
            <meta itemProp="position" content="2" />
          </li>
        </ol>
      </span>
      <AboutVisual></AboutVisual>
      <ContactLink className='downContactContainer'></ContactLink>
    </div>
  </>;
};
export default AboutMain;
