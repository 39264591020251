import bannerTitle from "../../frontImage/bannerTitle.svg";
import Slider from "react-slick";
import InternalLink from "components/UI/ InternalLink";

const Carousel = ({ carouselList }: { carouselList: any[] }) => {
  //針對輪播圖不一定每一個都有連結而設置
  const pushLink = (val: string) => {
    if (val !== "") {
      const url = val;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    }
  };
  // Slider 套件 setting
  const settings = {
    dots: true,
    infinite: true,
    speed: 1800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed:4000,
  };
  return (
    <>
      <div className="main">
        <div className="absolute bannerTitleContainer"data-aos="fade-left">
          <img className="absolute" src={bannerTitle} alt="智慧停車" />
          <div className="caorouselLink" >
            <InternalLink
              to="/award"
              title=" 智慧停車成功案例"
            ></InternalLink>
          </div>
        </div>
        <main className="carouselContainer "  >
          <Slider className="homeCarouselSwiper" {...settings}>
            {carouselList &&
              carouselList.slice(0, 5).map((val) => {
                return (
                  <div
                    key={val.id}
                    onClick={() => pushLink(val.url_link)}
                    className={val.url_link ? "hasUrl" : ""}
                  >
                    <img  src={val.image_src} alt={val.image_name}  loading="lazy"/>
                  </div>
                );
              })}
          </Slider>
        </main>
      </div>
    </>
  );
};
export default Carousel;
