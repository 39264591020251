import { useState, useEffect } from 'react'
import hireMain01 from '../../frontImage/hireMain01.png'
import hireMain02 from '../../frontImage/hireMain02.png'
import hireMain03 from '../../frontImage/hireMain03.png'
import hireMain04 from '../../frontImage/hireMain04.png'
import hireMain05 from '../../frontImage/hireMain05.png'
import hireMain06 from '../../frontImage/hireMain06.png'
import hireMain07 from '../../frontImage/hireMain07.png'
import hireMain08 from '../../frontImage/hireMain08.png'
import hireMain09 from '../../frontImage/hireMain09.png'

const HireMainSection = () => {
    const [textIndex, SetTextIndex] = useState(0)
    const [text, setText] = useState("")
    // 打字動畫
    const [fullText, setFullText] = useState(
        "加入我們，成為TIDC的一份子！".split('')
    )
    // 打字動畫共0.3秒
    useEffect(() => {
        if (textIndex < fullText.length) {
            setTimeout(() => {
                setText(text + fullText[textIndex])
                SetTextIndex(textIndex + 1)
            }, 300)
        }
    }, [fullText, text, textIndex])
    // 六大企業文化 的靜態資料
    const hireMainList = [
        {
            enLabel: 'Company culture',
            label: '六大企業文化',
            children: [
                {
                    title: '人本',
                    enTitle: 'Human-Centered',
                    src: hireMain01,
                    content: ["我們尊重每一個層級的職務", "我們重視員工的需求及照顧"
                    ],
                },
                {
                    title: '真誠',
                    enTitle: 'Sincere',
                    src: hireMain02,
                    content: ["我們重視內外關係並相互承諾", "我們坦然面對所有好壞並檢討"]

                },
                {
                    title: '踏實',
                    enTitle: 'Dependable',
                    src: hireMain03,
                    alt: '',
                    content: ["我們堅持成為實在經營的企業", "我們認同力量來自於團隊合作"]

                },
                {
                    title: '包容',
                    enTitle: 'Inclusion',
                    src: hireMain04,
                    content: ["我們包容同仁多元的意見及想法", "我們廣納不同背景的智慧與經驗"]
                },
                {
                    title: '創新',
                    enTitle: 'Innovation',
                    src: hireMain05,
                    content: ["我們不斷優化內外資源保持活性 ", "我們樂於跨部門交流並創造議題"]
                },
                {
                    title: '熱忱',
                    enTitle: 'Passion',
                    src: hireMain06,
                    content: ["我們認同企業理念並積極參與 ", "我們對於共同目標投入且熱忱"]
                },
            ]
        },
    ]
    // 徵才區的靜態資料
    const hireMainSecList = [
        {
            src: hireMain09,
            content: ["台灣國際最好的價值，是願意承擔企業的社會責任。我們向來以誠信作為員工照護的依歸，20年來默默扮演社會公民的角色，守護每一位員工的福祉與需求。", "人才是台灣國際保持領先的關鍵，我們相信、只有幸福的夥伴，才能打造有競爭力的團隊，持續營造更良善的薪酬與福利，一直是我們努力的目標。", "歡迎您帶著熱情與創意，加入台灣國際的大家庭！想知道最新工作機會，請點選「104 人力銀行或 CakeResume 」了解相關職缺詳情。"],
            children: [
                { link: 'https://www.cakeresume.com/companies/greenparking', alt: 'cakeResume', src: hireMain07 },
                { link: 'https://www.104.com.tw/company/1139q6ig', alt: '104', src: hireMain08 }
            ]
        },
    ]
    return (<>
        <div className='hireMainSection'>
            {hireMainList.map((val, index) => (
                <div className='container' key={index} >
                    <div className="titleContainer" data-aos="fade-down"
                        data-aos-duration="1000">
                        <span className="block">{val.enLabel}</span>
                        <h4>{val.label}</h4>
                    </div>
                    <div className='content' data-aos="fade-down"
                        data-aos-duration="1500">
                        {val.children.map((val, index) => (<div key={index} className='contentContainer'>
                            <div className='contenFirst'>
                                <div className='imgcontainer'>
                                    <img src={val.src} alt={val.title} />
                                </div>
                                <h4>{val.title}</h4>
                                <span>{val.enTitle}</span>
                            </div>
                            <div className='contentSec'>
                                <h4>{val.title}</h4>
                                <span>{val.enTitle}</span>
                                {val.content.map((val, index) => (
                                    <p key={index} >{val}</p>
                                ))}
                            </div>
                        </div>)
                        )}
                    </div>
                </div>
            ))}
            {hireMainSecList.map((val, index) => (<div className='secContainer' key={index} data-aos="fade-down"
                data-aos-duration="1500">
                <div className='content'>
                    <h2>{text}</h2>
                    <div className='contentContainer' data-aos="fade-down"
                        data-aos-duration="1800">
                        {val.content.map((val, index) => (
                            <p key={index} >{val}</p>
                        ))}
                    </div>
                    <div className='linkContainer' >
                        {val.children.map((val, index) => (
                            <a key={index} href={val.link} target="_blank" rel="noreferrer"><img src={val.src} alt={val.alt} /></a>
                        ))}
                    </div>
                </div>
                <div className='mainVisual' >
                    <img src={hireMain09} alt="TIDC團隊" />
                </div>
            </div>))}
        </div>
    </>
    )
}
export default HireMainSection