import AsideNav from '../../components/mainLayout/AsideNav'
import { Link } from 'react-router-dom'
import ContactLink from 'components/UI/ContactLink'
import ServiceMainVisual from 'components/serviceMain/ServiceMainVisual'
import { Helmet } from 'react-helmet-async'
const ServiceMain = () => {
    return (<>
        <Helmet>
            <title>服務方案｜TIDC台灣國際-智慧停車領航者</title>
        </Helmet>
        <AsideNav showIndex={1}></AsideNav>
        <div className='selfContainer'>
            <span className="breadcrumbs">
                <ol className="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/">
                            <span itemProp="name">首頁</span></Link>
                        <meta itemProp="position" content="1" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <span itemProp="name">服務方案</span>
                        <meta itemProp="position" content="2" />
                    </li>
                </ol>
            </span>
            <ServiceMainVisual ></ServiceMainVisual>
            <ContactLink className='downContactContainer'></ContactLink>
        </div>
    </>
    )

}
export default ServiceMain