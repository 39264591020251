import { getCarousel } from '../../utils/fetchAPI'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface carouselState {
    carouselList: string[]
    loading: boolean
    error:string
}
const initialState = {
    carouselList: [],
    loading: false,
    error:'',
} as carouselState
export const fetchData = createAsyncThunk(
    'api/carousel', async () => {
        const response = await getCarousel()
        return response.data;
    })

export const carouselSlice = createSlice({
    name: 'carousel',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(fetchData.pending, (state: carouselState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchData.fulfilled, (state: carouselState, action: { payload: any; }) => {
            state.loading = false
            state.carouselList = action.payload.datas;
            return state;
        })

        builder.addCase(fetchData.rejected, (state: carouselState, action: { error: { message: any; }; }) => {
            state.loading = false
            state.error = action.error.message
            return state
        })
    }
});


export const carouseActions = carouselSlice.actions;
export default carouselSlice.reducer