import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getNews, getNewsid, getNewsLatest } from '../../utils/fetchAPI'

interface newsState {
  content: any[]
  idContent: string[]
  latestContent: string[]
  loading: boolean
  error: string
  newsCheckList: any[]
}
const initialState = {
  content: [],
  idContent: [],
  latestContent: [],
  loading: false,
  error: "",
  newsCheckList: [1, 2]
} as newsState
export const fetchNews = createAsyncThunk(
  'api/news', async (val: object) => {
    const response = await getNews(val)
    return response.data;
  })
export const fetchNewsLates = createAsyncThunk(
  'api/newslatest', async () => {
    const response = await getNewsLatest()
    return response.data;
  })
export const fetchNewsIdContent = createAsyncThunk(
  'api/newsContent', async (val: object) => {
    const response = await getNewsid(val)
    return response.data;
  })
// share.js
const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    //頁籤功能
    // updateNewsCheckList(state, action) {
    //   state.newsState.newsCheckList = []
    //   state.newsState.newsCheckList.push(action.payload)
    // }
  },
  extraReducers: (builder: any) => {
    //fetchNews
    builder.addCase(fetchNews.pending, (state: newsState) => {
      state.loading = true
      return state;
    })
    builder.addCase(fetchNews.fulfilled, (state: newsState, action: { payload: any; }) => {
      state.loading = false
      state.content = action.payload.datas
      return state;

    })
    builder.addCase(fetchNews.rejected, (state: newsState, action: { error: { message: any; } }) => {
      state.loading = false
      state.error = action.error.message
      return state
    })
    //fetchNewsIdContent
    builder.addCase(fetchNewsIdContent.pending, (state: newsState) => {
      state.loading = true
      return state;
    })
    builder.addCase(fetchNewsIdContent.fulfilled, (state: newsState, action: { payload: any; }) => {
      state.loading = false
      state.content = action.payload.datas
      return state;

    })
    builder.addCase(fetchNewsIdContent.rejected, (state: newsState, action: { error: { message: any; } }) => {
      state.loading = false
      state.error = action.error.message
      return state
    })
    //fetchNewsLates
    builder.addCase(fetchNewsLates.pending, (state: newsState) => {
      return state;
    })
    builder.addCase(fetchNewsLates.fulfilled, (state: newsState, action: { payload: any; }) => {
      state.loading = false
      state.latestContent = action.payload.datas
      return state;

    })
    builder.addCase(fetchNewsLates.rejected, (state: newsState, action: { error: { message: any; } }) => {
      state.loading = false
      state.error = action.error.message
      return state
    })
    // builder.addCase(fetchNewsIdContent.pending, (state: newsState) => {
    //   return state;
    // })
    // builder.addCase(fetchNewsIdContent.fulfilled, (state:newsState, action: { payload: any; }) => {
    //   state.loading = true
    //   state.idContent = action.payload.datas
    //   return state;

    // })

    // builder.addCase(fetchNewsIdContent.rejected, (state: newsState, action: { error: { message: any; } }) => {
    //   state.loading = true
    //   state.error = action.error.message
    //   return state
    // })
  }

})
export const newsrActions = newsSlice.actions;



export default newsSlice.reducer



