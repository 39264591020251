import { Link } from 'react-router-dom'
import ContactForm from 'components/contactMain/ContactForm'
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
const ContactMain = () => {

    const [textIndex, SetTextIndex] = useState(0)
    const [text, setText] = useState("")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fullText, setFullText] = useState(
        "嗨！ 有什麼可以為您服務的呢？".split('')
    )
    useEffect(() => {
        if (textIndex < fullText.length) {
            setTimeout(() => {
                setText(text + fullText[textIndex])
                SetTextIndex(textIndex + 1)
            }, 200)
        }
    }, [fullText, text, textIndex])
    return (<>
        <Helmet>
            <title>聯絡我們｜TIDC台灣國際-智慧停車領航者</title>
        </Helmet>
        <div className='selfContainerPage'>
            {/* bread */}
            <div className='contactMainForm'>
                <span className="breadcrumbs">
                    <ol className="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
                        <li itemProp="itemListElement" itemScope
                            itemType="https://schema.org/ListItem">
                            <Link itemProp="item" to="/">
                                <span itemProp="name">首頁</span></Link>
                            <meta itemProp="position" content="1" />
                        </li>
                        <li itemProp="itemListElement" itemScope
                            itemType="https://schema.org/ListItem">
                            <span itemProp="name">聯絡我們</span>
                            <meta itemProp="position" content="2" />
                        </li>
                    </ol>
                </span>
                <div className='contactSection'>

                    <div className='container'>
                        <div className='titleContainer'>
                            <h4>Contact Us</h4>
                            <span>聯絡我們</span>
                        </div>
                        <div className='mainvIusalContainer'>
                            <div className='mainvIusal'>
                            </div>
                        </div>
                        <div className='contactContainer'>
                            <span className='title'>{text}</span>
                            <ContactForm></ContactForm>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )

}
export default ContactMain