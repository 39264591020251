
import service01 from '../../frontImage/service01.png'

const ServiceMainVisual = () => {
    return (<>
        <div className="flex justify-between items-center gap-8 mb-40 visualContainer 2xl:mb-60 " data-aos="fade-down"
            data-aos-duration="1500">
            <div className="visualContent">
                <div className='visualTitleContainer'>
                    <h3 className="enTitle">service plan</h3>
                    <h2 className="visualTitle">服務方案</h2>
                </div>
                <p>
                    提供政府及企業在停車場域的規畫經營與建議。<br />從服務缺口發展適切的方案、以使用者為中心規劃人本友善的設施及服務，創造便利且使用體驗滿分的場域及服務。<br />
                </p>
                <p >同時專注於智慧停車領域，致力於創新研發，強調停車大數據的整合、軟體研發的技術。<br />開發人性化且易操作的停車管理系統，並建構彙整 IoT 資訊的整合平台，從停車場域落實服務應用，轉化科技應用營造優質的數位服務體驗。</p>
            </div>
            <div className="visualImg 2xl:w-1/2  sm:w-4/5  w-full">
                <img src={service01} alt="服務方案" />
            </div>
        </div>
    </>)

}
export default ServiceMainVisual