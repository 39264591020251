import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const PageNotFound = () => {

    return (
        <>
            <Helmet>
                <title>404找不到頁面｜智慧停車領航者</title>
            </Helmet>
            <div className='h-screen notFound '>
                <div className='imgContainer'>

                </div>
                <div className='titleContainer flex' >
                    <h4>出現了空間跳躍的問題！</h4>
                    <div className='linkContainer'>
                        <Link to="/" ><span className='block'>回到首頁</span></Link >
                    </div>
                </div>
            </div>
        </>
    )
}
export default PageNotFound