import { Link } from "react-router-dom";
import logo from "../../frontImage/logo.svg";
import Slider from "react-slick";
const HomeNews = ({ latest, pin }: { latest: any[], pin: any[] }) => {
    // Slider 套件 setting
    const settings = {
        dots: true,
        infinite: true,
        speed: 1800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
    };
    return (
        <div className="homeNews" data-aos="fade-down"
            data-aos-duration="1200">
            <div className="homeNewsContainer" >
                <Slider className="carouselContainer" {...settings}>
                    {pin && pin.map((val) => {
                        return (
                            <Link to={`/news/${val.id}`} key={val.id}>
                                <div key={val.id}>
                                    <img src={val.image_src} alt={val.image_alt} />
                                    <div className="homeNewImgTitle">
                                        {/* 當大於14則會加上．．．*/}
                                        {/* <span>{val.title.length > 14 ? val.title.substring(0, 14) + '...' : val.title}</span> */}
                                        <span>{val.title}</span>

                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </Slider>
                <div className="homeNewsDetail">
                    <div className="homeNewsTitle">
                        <div className="flex gap-3 items-center">
                            <div className="homeNewsTitleIcon">
                                <img src={logo} alt="台灣國際" />
                            </div>
                            <h3>最新消息</h3>
                        </div>
                        <div className="homeNewsLinkCon">
                            <Link className="homeNewsLink" to="/news">
                                點我看更多
                            </Link>
                        </div>

                    </div>
                    <div className="homeNewCardContainer">
                        {latest && latest.map((val) => (
                            <div className="homeNewCard" key={val.id}>
                                <div className="homeNewTitle">
                                    {/* 當字數大於20則會加上．．．*/}
                                    {val.title.trim().length > 20 ? val.title.trim().substring(0, 20) + '...' : val.title}
                                </div>
                                <div className="homeNewImg">
                                    <img src={val.image_src} alt={val.image_alt} />
                                </div>
                                {/* 過濾掉所有html標籤，就會僅剩文本，當大於字數34則會加上．．． */}
                                <p className="homeNewContent" dangerouslySetInnerHTML={{ __html: val.content.replaceAll(/(<([^>]+)>)/ig, '').substring(0, 34) + "..." }}></p>
                                <Link className="homeNewsLinkCard" to={`/news/${val.id}`}>
                                    觀看文章
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Link className="homeNewsLinkRwd" to="/news">
                點我看更多
            </Link>
        </div>
    );
};
export default HomeNews;
