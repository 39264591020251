import { getCorpContent, getCorpMain } from '../../utils/fetchAPI'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
interface corpState {
    index: string[]
    content: string[]
    total: string[]
    loading: boolean
    error: string
}
const initialState = {
    index: [],
    content: [],
    total: [],
    loading: false,
    error: "",
} as corpState

export const fetchcorpMain = createAsyncThunk(
   //合作夥伴主表 api
    'api/corp', async () => {
        const response = await getCorpMain()
        return response.data;
    })
export const fetchcorpContent = createAsyncThunk(
     //合作夥伴副表 api
    'api/corpContent', async () => {
        const response = await getCorpContent()
        return response.data;
    })
export const corpSlice = createSlice({
    name: 'corp',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers: (builder: any) => {
        //合作夥伴主表
        builder.addCase(fetchcorpMain.pending, (state: corpState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchcorpMain.fulfilled, (state: corpState, action: { payload: any; }) => {
            state.loading = false
            state.index = action.payload.datas;
            return state;
        })

        builder.addCase(fetchcorpMain.rejected, (state: corpState, action: { error: { message: any; }; }) => {
            state.loading = false
            return state
        })
        //合作夥伴副表
        builder.addCase(fetchcorpContent.pending, (state: corpState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchcorpContent.fulfilled, (state: corpState, action: { payload: any; }) => {
            let tdata: any
            tdata = action.payload.datas;
            // 抓到所有合作夥伴的資料後
            // 依照 https://hsiang41.atlassian.net/wiki/spaces/TDIC/pages/34144664/TIDC 的合作夥伴詳細分類進行排序
            tdata.sort(function (a: { category_name: number }, b: { category_name: number }) {
                return a.category_name - b.category_name;
            });
            state.content = tdata
            state.loading = false
            return state;
        })
        builder.addCase(fetchcorpContent.rejected, (state: corpState, action: { error: { message: any; }; }) => {
            state.loading = false
            return state
        })
    }
});

export const corpActions = corpSlice.actions;
export default corpSlice.reducer