import { useEffect, useState } from 'react'
import about01 from '../../frontImage/about01.png'
const AboutVisual = () => {
    const [textIndex, SetTextIndex] = useState(0)
    const [text, setText] = useState("")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fullText, setFullText] = useState(
        "台灣國際 串聯你的智慧生活".split('')
    )
    useEffect(() => {
        if (textIndex < fullText.length) {
            setTimeout(() => {
                setText(text + fullText[textIndex])
                SetTextIndex(textIndex + 1)
            }, 200)
        }
    }, [fullText, text, textIndex])
    return (<>
        <div className="flex justify-between items-center gap-8 mb-40 2xl:mb-60 visualContainer" data-aos="fade-down"
            data-aos-duration="1500">
            <div className="visualContent">
                <div className='visualTitleContainer'>
                    <h3 className="enTitle">About us</h3>
                    <h2 className="visualTitle">關於我們</h2>
                </div>
                <span className="subTitle">{text}</span>
                <p >
                我們秉持「以人為本」的初心，遵循「與時俱進」並勇於挑戰的宗旨，精耕停車場域的規劃與執行18年。<br/>除了設計細膩、完整的服務體驗流程，同步專注於智慧停車領域，致力於創新研發，強調停車大數據的整合、軟體研發的技術，開發人性化且易操作的停車管理系統，從停車場域落實服務應用，轉化科技應用營造優質的數位服務體驗，獲得各界肯定成為智慧停車的領航者。
                </p>
            </div>
            <div className="visualImg 2xl:w-1/2  sm:w-4/5  w-full">
                <img src={about01} alt="關於我們" />
            </div>
        </div>
    </>)

}
export default AboutVisual