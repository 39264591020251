import AsideNav from '../../components/mainLayout/AsideNav'
import ServiceInter from '../../components/serviceMain/ServiceInterMain'
import ServiceInterSection from '../../components/serviceMain/ServiceInterSection'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../store/index";
//需要抓資料的要變更後面的路徑
import { fetcInterMain, fetchInterContent } from "../../store/service/inter";
import { Helmet } from 'react-helmet-async'
import Loading from 'components/mainLayout/Loading'
const ServiceMain = () => {
    const dispatch = useDispatch<any>();
    // 需要拿到的資料
    const inter = useSelector((state: RootState) => state.inter.index);
    const interContent = useSelector((state: RootState) => state.inter.content)
    //抓loading
    // const loadingState = useSelector((state: RootState) => state.inter.loading)
    useEffect(() => {
        dispatch(fetcInterMain());
        dispatch(fetchInterContent());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }, [loading]);
    return (<>
        {
            loading ? <Loading></Loading> : null
        }
        <Helmet>
            <title>服務方案-資訊整合｜TIDC台灣國際-智慧停車領航者</title>
        </Helmet>
        <AsideNav showIndex={1}></AsideNav>
        <div className='selfContainer'>
            <span className="breadcrumbs">
                <ol className="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/">
                            <span itemProp="name">首頁</span></Link>
                        <meta itemProp="position" content="1" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/service"><span itemProp="name">服務方案</span></Link>
                        <meta itemProp="position" content="2" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <span itemProp="name">資訊整合</span>
                        <meta itemProp="position" content="3" />
                    </li>
                </ol>
            </span>
            <ServiceInter></ServiceInter>
            <ServiceInterSection inter={inter} interContent={interContent}></ServiceInterSection>
        </div>
    </>
    )

}
export default ServiceMain