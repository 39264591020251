const LeftLinkBtn = ({ title, href, className, ...props }: {
  title: string,
  href: string,
  className?: string,
  props?: any
}
) => {
  return (
    <div className='leftLinkContainer'>
      <a
        className="leftLink"
        target="_blank" rel="noreferrer"
        href={href}
        {...props}
      >
        {title}
      </a>
    </div>


  );
};
export default LeftLinkBtn;
