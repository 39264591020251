import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/tailwind.css";
import "./assets/all.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store } from './store/index'
import { Provider } from 'react-redux'
// const root = ReactDOM.createRoot(document.getElementById("root")!) !必須得存在否則會噴錯;
const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);
reportWebVitals();
