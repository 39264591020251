import ContactLink from 'components/UI/ContactLink'
import aboutesg01 from '../../frontImage/aboutesg04.png'
import aboutesg02 from '../../frontImage/aboutesg02.png'
import aboutesg03 from '../../frontImage/aboutesg03.png'
import aboutesg04 from '../../frontImage/aboutesg01.png'


const AboutEsqSection = () => {
    const aboutEsqList = [{
        title: '氣候行動',
        content: ["即時引導減少溫室氣體", "數據中心推智慧停車", "智慧停車服務體驗APP"],
        src: aboutesg01,
        alt: 'aboutesg01'

    }, {
        title: '尊嚴就業與經濟成長',
        content: ["多元勞動力代表", "人才吸引與保留", "雇用弱勢族群"],
        src: aboutesg02,
        alt: 'aboutesg02'
    }, {
        title: '良好健康與福祉',
        content: ["高於同業薪酬福利", "人力資本投資", "友善職場環境"],
        src: aboutesg04,
        alt: 'aboutesg04'
    }, {
        title: '永續的消費與生產模式',
        content: ["大數據收容與賦能", "低碳電動車巡場", "空位預測減少交通壅塞"],
        src: aboutesg03,
        alt: 'aboutesg03'
    }]
    return (
        <div className='aboutEsq'>
            {aboutEsqList.map((val: any, index: number) => (
                <div key={index} className="container" data-aos="fade-down"
                data-aos-duration="1500">
                    <div className="content">
                        <span className="aboutMainVisualTitle">{val.title}</span>
                        <div className="contentContainer">
                            {val.content.map((val: any, index: number) => (<p key={index}>
                                {val}
                            </p>))}
                        </div>
                    </div>
                    <div className="mainVisualImg">
                        <img src={val.src} alt={val.alt} />
                    </div>
                </div>
            ))}
            <ContactLink className='downContactContainer'></ContactLink>
        </div>
    )
}
export default AboutEsqSection