import { Link } from "react-router-dom";

const Navigation = ({ title }: { title?: any }) => {
  return (
    <>
      <div className='flex navContainer'>
        <div className="flex gap-12 tracking-wider">
          {title.map((item: any) => <Link  to={item.url}
            key={item.label} >{item.label}</Link>)}
        </div>
      </div>
    </>


  );
};
export default Navigation;
