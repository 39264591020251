import { configureStore, createSlice } from '@reduxjs/toolkit'
import counterReducer from './home/counter'
import newsReducer from './news/frontNews'
import carouselReducer from './home/carousel'
import timelineReducer from './about/timeline'
import teamReducer from './about/team'
import corpReducer from './about/corp'
import personnalReducer from './hire/personnal'
import awardReducer from './award/awardcontent'
import interReducer from './service/inter'
import iotReducer from './service/iot'
import homenewsReducer from './home/homenews'
import newsNextPrevReducer from './news/newsNextPrev'
import contactReducer from './contact/contact'

let headerState = {
  headerList: [
    {
      label: "關於我們",
      labelEn: "About us",
      url: "/about",
      children: [
        {
          url: "/about/history",
          label: "TIDC 沿革",
          show: false
        },
        {
          url: "/about/brand",
          label: "TIDC 品牌",
          show: false
        },
        {
          url: "/about/team",
          label: "TIDC TEAM",
          show: false
        },
        {
          url: "/about/cop",
          label: "TIDC 合作夥伴",
          show: false
        },
        {
          url: "/about/esg",
          label: "永續ESG",
          show: false
        },
      ],
    },
    {
      label: "服務方案",
      url: "/service",
      labelEn: "Service",
      children: [
        {
          url: "/service/information-integration",
          label: "資訊整合",
        },
        {
          url: "/service/smart-parking-solution",
          label: "智慧停車規劃",
        },

      ],
    },
    {
      label: "各界肯定",
      labelEn: "Award",
      url: "/award",
      children: [
        {
          url: "/award/gta",
          label: "金擘獎 ",
        },
        {
          url: "/award/fiabci",
          label: "國家卓越建設獎 ",
        },
        {
          url: "/award/cloudaward",
          label: "雲端物聯網創新獎 ",
        },
        {
          url: "/award/servicequality",
          label: "服務品質獎項 ",
        },


      ],
    },
    {
      label: "人力資源",
      labelEn: "Careers",
      url: "/careers",
      children: [
        {
          url: "/careers/employee-benefits",
          label: "福利及政策 ",
        },
        //員工專區暫時先移除
        // {
        //   url: "/careers/personnel02",
        //   label: "員工專區  ",
        // },
      ],
    },
    {
      label: "最新消息",
      labelEn: "News",
      url: "/news",
      children: [
        {
          id: 1,
          url: "/news/company",
          label: "企業快訊",
        },
        {
          id: 2,
          url: "/news/media",
          label: "媒體報導",
        },
      ],
    },
    {
      label: "聯絡我們",
      labelEn: "Contact",
      url: "/contact",
      children: [
      ],
    },
  ],
  showIndex: 0
}
let asideNavState = {
  showIndex: -1
}
const headerSlice = createSlice({
  name: 'header',
  initialState: headerState,
  reducers: {
  },
})
const asideNavSlice = createSlice({
  name: 'asideNav',
  initialState: asideNavState,
  reducers: {
    // //頁籤功能
    // updateAsideNavCheck(state, action) {
    //   // asideNavState.showIndex = []
    //   asideNavState.showIndex = action.payload
    //   // asideNavState.showIndex=(action.payload)
    //   // console.log(action.payload)
    // }
  },
})
let loadingState = false
const loadingSlice = createSlice({
  name: 'loading',
  initialState: loadingState,
  reducers: {
    switchLoading(state, action) {
      state = action.payload
    }
  },
})
//configureStore is create store (=createStore)and can merge mutli store
export const store = configureStore({
  reducer: {
    header: headerSlice.reducer,
    asideNav: asideNavSlice.reducer,
    counter: counterReducer,
    loading: loadingSlice.reducer,
    news: newsReducer,
    carousel: carouselReducer,
    timeline: timelineReducer,
    team: teamReducer,
    corp: corpReducer,
    personnal: personnalReducer,
    award: awardReducer,
    inter: interReducer,
    iot: iotReducer,
    homenews: homenewsReducer,
    newsNextPrev: newsNextPrevReducer,
    contact: contactReducer


  },
})
// 把所有需要的export再去元件裡面把需要的action拿出拉
export const headerActions = headerSlice.actions;
export const asideNavActions = asideNavSlice.actions;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store


