import ContactLink from "components/UI/ContactLink";
import { useEffect, useState } from "react";
import { combinTable } from "utils/dataHandling";

const ServiceInterMain = ({ inter, interContent }: { inter: string[], interContent: string[] }) => {
    const [total, settotal] = useState<any[] | undefined | void>()
    useEffect(() => {
        const main = [...inter]
        const content = [...interContent]
        if (inter && interContent) {
            return settotal(combinTable(main, content))
        }
    }, [inter, interContent])
   
    return (
        <>
            <div className="serviceInterSection">
                {total && total.map((val) => (<div key={val.id} className="container " data-aos="fade-down"
                    data-aos-duration="1500">
                    <div className="content">
                        <div className="titleContainer">
                            <span className="title">{val.title}</span>
                            <span className="entitle"> {`-${val.e_title}`}</span>
                        </div>
                        <ul>
                            {val.dataList.map((val: any) => (
                                <li className="dataList" key={val.id}>
                                    {val.content}
                                </li>
                            ))}
                        </ul>
                        <p className="detail">{val.content}</p>
                    </div>
                    <div className="mainVisualImg" >
                        <img src={val.image_src} alt={val.image_name} />
                    </div>
                </div>)

                )}
                <ContactLink className='downContactContainer'></ContactLink>
            </div>
        </>
    );
};
export default ServiceInterMain;
