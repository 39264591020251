import CountUp from 'react-countup'
import { useState } from 'react'
// 是滑到該區塊後，再有計數器動畫
import ScrollTrigger from 'react-scroll-trigger'
const Counter = ({ counterList }: { counterList: any[] }) => {
    const [counterOn, setCounterOn] = useState(false)
    return (
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            <div className="counterContainer" data-aos="fade-down"
                data-aos-duration="1500" >
                <div className='counterDiv'>
                    {counterList.map((val) => (
                        <div key={val.id} className="counter flex">
                            <div className='counterBox'>
                                <div className='counterBoxImg'>
                                    <img src={val.icon_src} alt={val.title} />
                                </div>
                                <div className='counterDetail'>
                                    <p className='counterTitle'>{val.title}</p>
                                    <p className="flex">{counterOn && <CountUp className='counterNum' start={0} end={val.value} duration={10}
                                    />}<span >&ensp;&ensp;{val.unit}</span></p>
                                </div>
                            </div>
                        </div>
                    ))

                    }

                </div>
            </div>
        </ScrollTrigger>
    )

}
export default Counter