
import Navigation from "./Navigation";
import NavHamMenu from "./NavHamMenu";
import { Link } from 'react-router-dom';
import type { RootState } from '../../store/index'
import { useSelector } from 'react-redux'
const Header = () => {
  const headerList = useSelector((state: RootState) => state.header.headerList)
  return (
    <div className="headerFront">
      <div >
        <Link to="/" className="headerLogo" ></Link>
      </div>
      {/* 漢堡選單 */}
      <NavHamMenu title={headerList} />
      {/* 側邊選單 */}
      <Navigation title={headerList} />
    </div>


  );
};
export default Header;
