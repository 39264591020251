import { forwardRef } from 'react'

interface Props {
    label?: string,
    name: string,
    placeholder?: string,
    className?: string,
    inputClassName?: string,
    labelClassName?: string,
    required?: boolean,
    error?: object,
    noLabel?: boolean,
    disabled?: boolean,
    defaultValue?: string,
    value?: string,
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => void,
}

const SelectField = forwardRef<HTMLSelectElement, Props>(
    ({
        name,
        label,
        onChange,
        onBlur,
        className,
        inputClassName,
        labelClassName,
        defaultValue,
        required,
        placeholder,
        error,
        noLabel = false,
        disabled = false,
        value
    }: Props, ref) => {

        return <div className={`inputField ${className}`}>
            <span className="formcontentspan">*</span>

            {
                !noLabel && <>
                    {
                        label && <label htmlFor="" className={

                            `${error ? 'text-error' : 'text-gray-label'}  ${labelClassName}`}>
                            {` ${label}\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0\u00A0`}

                        </label>
                    }
                </>
            }

            <select
                ref={ref}
                disabled={disabled}
                name={name}
                defaultValue={defaultValue}
                placeholder={placeholder}
                value={value}
                id={label}
                className={
                    `${error ? 'border-error' : 'border-gray-400'}
       
          field
         ${inputClassName}`
                }
                onChange={onChange}
                onBlur={onBlur} >
                <option value="" >請選擇</option>
                <option value="智慧停車規劃">智慧停車規劃</option>
                <option value="顧問諮詢服務">顧問諮詢服務</option>
                <option value="資訊整合服務">資訊整合服務</option>
                <option value="資訊整合服務">品牌異業合作</option>
                <option value="其他業務合作">其他業務合作</option>
            </select>
        </div>;
    }
)
export default SelectField;