import { Link } from "react-router-dom";
const NewsNav = ({ newsContent }: { newsContent: any[] }) => {

  return (
    <>
      <div className="newsNav">
        <div className="titleContainer">
          <span className="title">最新文章</span>
        </div>
        <div className="container">
          {newsContent &&
            newsContent.map((val) => (
              <Link
                className="containerCard"
                key={val.id}
                to={`/news/${val.id}`}
              >
                <div className="contentContainer" key={val.id}>
                  <div className="imgContainer">
                    <img src={val.image_src} alt={val.image_alt} />
                  </div>
                  <div className="content">
                    <div className="title">
                      <span className="date">{val.date}</span>
                      <span className="label">{val.label_name}</span>
                    </div>
                    <p className="descruption"> {val.title.length > 5 ? val.title.substring(0, 15) + '...' : val.title}</p>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </>
  ); 
};
export default NewsNav;
