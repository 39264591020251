import Header from "./components/mainLayout/Header";
import Footer from "./components/mainLayout/Footer";
import { Provider } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import store from "./store/index";
import routes from "./routerConfig";
import { useEffect, useState } from "react";
import Aos from "aos";
import Cookie from "components/UI/Cookie";
import { HelmetProvider } from 'react-helmet-async';
function App() {
  useEffect(() => {
    Aos.init({ once: true, });
  }, []);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [loading, pathname]);
  return (
    <HelmetProvider>
      <Provider store={store}>
        <div >
          <Cookie></Cookie>
          <div>
            <Header />
            <Routes>
              {routes.map((val, index) => (
                <Route

                  path={val.path}
                  key={index}
                  element={val.component}
                />
              ))}
            </Routes>

            <Footer />
          </div>
        </div>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
