import { useEffect, useState } from 'react'
import esg01 from '../../frontImage/esg01.png'
const AboutEsgVisual = () => {
    const [textIndex, SetTextIndex] = useState(0)
    const [text, setText] = useState("")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fullText, setFullText] = useState(
        "打造與世界共好的綠色交通".split('')
    )
    useEffect(() => {
        if (textIndex < fullText.length) {
            setTimeout(() => {
                setText(text + fullText[textIndex])
                SetTextIndex(textIndex + 1)
            }, 200)
        }
    }, [fullText, text, textIndex])
    return (<div className="aboutMainVisual">
        <div className="aboutMainVisualContainer">
            <div className="aboutMainVisualContent"  data-aos="fade-down"
            data-aos-duration="1000">
                <span className="aboutMainVisualTitle">以人為本的永續方針<br/>{text}</span>
                <p >
                台灣國際透過建構友善職場環境、對社會負責任的態度、永續發展的方式經營業務為目標，導入ESG相關政策，參與社會公益並於業務執行中規劃落實環保愛地球的具體措施與減碳績效，並持續關切與員工的福祉息息相關的未來計劃，吸引和留住來自不同背景、積極主動且敬業樂業的員工，激發台灣國際更好的成長，成就台灣國際一貫以人為本的企業風格，創造正向的回饋循環。
                </p>
            </div>
            <div className="aboutMainVisualImg"  data-aos="zoom-out-down"
            data-aos-duration="1000">
                <img src={esg01} alt="ESG意象循環圖" />
            </div>
        </div>
    </div>)

}
export default AboutEsgVisual