import AsideNav from '../../components/mainLayout/AsideNav'
import { Link } from 'react-router-dom'
import HirePolicyMain from 'components/hire/HirePolicyMain'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetcHrMain, fetchHrContent } from 'store/hire/personnal';
import { RootState } from 'store'
import { Helmet } from 'react-helmet-async'
import Loading from 'components/mainLayout/Loading'
const HirePolicy = () => {
    const dispatch = useDispatch<any>();
    const hr = useSelector((state: RootState) => state.personnal.index);
    const hrContent = useSelector((state: RootState) => state.personnal.content);
    //抓loading
    // const loadingState = useSelector((state: RootState) => state.personnal.loading)
    useEffect(() => {
        dispatch(fetcHrMain());
        dispatch(fetchHrContent());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }, [loading]);
    return (<>
        {
            loading ? <Loading></Loading> : null
        }
        <Helmet>
            <title>人力資源-福利與政策｜TIDC台灣國際-智慧停車領航者</title>
        </Helmet>
        <AsideNav showIndex={3}></AsideNav>
        <div className='selfContainer'>
            <span className="breadcrumbs">
                <ol className="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/">
                            <span itemProp="name">首頁</span></Link>
                        <meta itemProp="position" content="1" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/careers">
                            <span itemProp="name">人力資源</span></Link>
                        <meta itemProp="position" content="2" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <span itemProp="name">福利與政策</span>
                        <meta itemProp="position" content="3" />
                    </li>
                </ol>
            </span>
            <HirePolicyMain hr={hr} hrContent={hrContent} ></HirePolicyMain>


        </div>
    </>
    )

}
export default HirePolicy

