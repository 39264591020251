import ContactLink from "components/UI/ContactLink";
import { useEffect, useState } from "react";
import { combinTable } from "utils/dataHandling";
import hirepolicy01 from "../../frontImage/hirepolicy01.png";
import hirepolicy02 from "../../frontImage/hirepolicy02.png";
import hirepolicy03 from "../../frontImage/hirepolicy03.png";
import hirepolicy04 from "../../frontImage/hirepolicy04.png";
import hirepolicy05 from "../../frontImage/hirepolicy05.png";
import hirepolicy06 from "../../frontImage/hirepolicy06.png";

const HirePolicyMain = ({ hr, hrContent }: { hr: string[], hrContent: string[] }) => {
    const [total, settotal] = useState<any[] | undefined | void>()
    useEffect(() => {
        
        const main = [...hr]
        const content = [...hrContent]
        if (main && content) {
            return settotal(combinTable(main, content))
        }
    }, [hr, hrContent])
    
    const hirePolicyList = [
        {
            enLabel: "Benefits and Policies",
            label: "五大福利與政策",
            children: [
                {
                    title: "健康照護",
                    enTitle: "health care",
                    src: hirepolicy02,
                    content: ["定期健檢", "醫療慰問", "分娩關懷"],
                },
                {
                    title: "保險照護",
                    enTitle: "insurance",
                    src: hirepolicy03,
                    content: ["勞工保險", "健康保險", "團體保險"],
                },
                {
                    title: "生活福利",
                    enTitle: "Welfare",
                    src: hirepolicy04,
                    alt: "",
                    content: ["彈性工時", "結婚賀禮", "團體保險"],
                },
                {
                    title: "娛樂活動",
                    enTitle: "entertainment",
                    src: hirepolicy05,
                    content: ["部門聚餐", "零食無限供應", "身心健康運動"],
                },
                {
                    title: "員工協助方案",
                    enTitle: "Employee Assistance Program",
                    src: hirepolicy06,
                    content: [
                        "心理諮商",
                        "法律諮詢",
                        "醫療諮詢",
                        "管理諮詢",
                        "財務諮詢",
                        "危機處理",
                    ],
                },
            ],
        },
    ];
    return (
        <>
            <div className="hirePolicyMain">
                <div className="mainVisual">
                    <div className="mainVisualContent" data-aos="fade-down"
                        data-aos-duration="1500">
                        <h2>台灣國際的福利與政策</h2>
                        <p>
                            對台灣國際的夥伴而言，生活的豐富和專業的成就同等重要；從食衣住行的滿足到精神層面的提升，我們都獲得企業充分的照顧。
                        </p>
                    </div>
                    <div className="mainVisualImg" data-aos="zoom-out-down"
                        data-aos-duration="1500">
                        <img src={hirepolicy01} alt="資訊整合" />
                    </div>
                </div>
                {/*  */}
                {hirePolicyList.map((val, index) => (
                    <div className="container" key={index} data-aos="fade-down"
                        data-aos-duration="1500" >
                        <div className="titleContainer">
                            <span className="block">{val.enLabel}</span>
                            <h4>{val.label}</h4>
                        </div>
                        <div className="content">
                            {val.children.map((val, index) => (
                                <div key={index} className="contentContainer">
                                    <div className="contenFirst">
                                        <div className="imgcontainer">
                                            <img src={val.src} alt={val.title} />
                                        </div>
                                        <h4>{val.title}</h4>
                                        <span>{val.enTitle}</span>
                                    </div>
                                    <div className="contentSec">
                                        <h4>{val.title}</h4>
                                        <span>{val.enTitle}</span>
                                        <ul>
                                            {val.content.map((val, index) => (
                                                <li key={index}>{val}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                <div className="secContainer">
                    {total && total.map((val) => (
                        <div key={val.id} className="secContentContainer" data-aos="fade-down"
                            data-aos-duration="1500" >
                            <div className="content">
                                <h2>{val.title}</h2>
                                <ul className="contentContainer">
                                    {val.dataList.map((val: { id: number; content: string; }) => (
                                        <li key={val.id}>{val.content}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mainVisual">
                                <img src={val.image_src} alt={val.image_name} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <ContactLink className='downContactContainer'></ContactLink>

            {/*  */}
        </>
    );
};
export default HirePolicyMain;
