import AsideNav from '../../components/mainLayout/AsideNav'
import AboutBrandSection from '../../components/aboutMain/AboutBrandSection'
import AboutBrandVisual from 'components/aboutMain/AboutBrandVisual'
import { Link } from 'react-router-dom'
import Breadcrumbs from 'components/UI/Breadcrum'
import { Helmet } from 'react-helmet-async'

const AboutBrand = () => {
  // //改寫變包屑
  // const location = useLocation()
  // const result = location.pathname.split('/')
  // const tdata: string[] = [];

  // useEffect(() => {

  //   // // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  //   // result.includes('about') ? tdata.push('關於我們') : ""
  //   // // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  //   // result.includes('history') ? tdata.push('TIDC 沿革') : ""

  // }, [])

  // // transferResult()

  return <>
    <Helmet>
      <title>關於我們-品牌｜TIDC台灣國際-智慧停車領航者</title>
    </Helmet>
    <AsideNav showIndex={0}></AsideNav>
    <Breadcrumbs></Breadcrumbs>
    <div className='selfContainer'>
      <div className="breadcrumbs">
        <ol itemScope itemType="https://schema.org/BreadcrumbList">
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <Link itemProp="item" to="/">
              <span itemProp="name">首頁</span></Link>
            <meta itemProp="position" content="1" />
          </li>
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <Link itemProp="item" to="/about">
              <span itemProp="name">關於我們</span></Link>
            <meta itemProp="position" content="2" />
          </li>
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <span itemProp="name">TIDC 品牌</span>
            <meta itemProp="position" content="3" />
          </li>
        </ol>
      </div>
      <AboutBrandVisual ></AboutBrandVisual>
      <AboutBrandSection ></AboutBrandSection>

    </div>
  </>;
};
export default AboutBrand;
