import { Link } from "react-router-dom";
import brandicon01 from "../../frontImage/brandicon01.svg";
import brandicon03 from "../../frontImage/brandicon03.svg";
import youtube01 from "../../frontImage/youtube01.svg";
import facebook01 from "../../frontImage/facebook01.svg";

const Footer = () => {
  // footer 的靜態資料
  const footerList = [
    {
      mobileLabel:"地址",
      label: "公司地址",
      content: "407 台中市西屯區文心路二段598號3樓-1",
    },
    {
      mobileLabel:"信箱",
      label: "公司信箱",
      content: "service@greenparking.com.tw",
    },
    {
      mobileLabel:"電話",
      label: "公司電話",
      content: "04 - 2310 - 3319",
    },
  ];
   // social 的靜態資料
  const iconlink = [
    {
      link: "https://www.facebook.com/TaiwanInternationalDevelopmentCo/",
      src: brandicon01,
      changesrc: facebook01,
      icon: "facebook",
    },
    {
      link: "https://www.youtube.com/@TIDC-Official",
      changesrc: youtube01,
      src: brandicon03,
      icon: "youtube",
    },
  ];
  return (
    <footer className="footer " id="footer">
      <div className="footerContainer">
        <Link to="/" className="footerLogoContainer "></Link>
        <div className="detailContainer">
          {footerList.map((item: any, index: number) => (
            <div key={index}>
              <div className="container">
              <span className="mobileName">{item.mobileLabel}</span>
                <span className="name">{item.label}</span>
                <p className="detail">{item.content}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="socialContainer">
          <ul className="social">
            {iconlink.map((val, index: number) => (
              <li key={index}>
                <a className="socialLink" target="blank" href={val.link}>
                  <img src={val.src} alt={val.icon} />
                  <img className="socialImg" src={val.changesrc} alt={val.icon} />
                </a>
              </li>
            ))}
          </ul>
          <div className="flex flex-col remarkContainer">
            <div className="flex flex-row self-end mb-1">
              <span></span>
              台灣國際開發事業有限公司&ensp;│&ensp;
              <Link to="/policy" className="policyLink">
                <span className="block cursor-pointer">隱私權政策</span>
              </Link>
            </div>
            <span className="copyRight block">
              Copyright © 2023 TIDC.All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
