import AsideNav from '../../components/mainLayout/AsideNav'
import NewsSection from '../../components/newsMain/NewsSection'
import NewsNav from '../../components/newsMain/NewsNav'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { fetchNewsLates } from 'store/news/frontNews'
import Loading from 'components/mainLayout/Loading'
const NewsIndex = () => {
    const dispatch = useDispatch<any>();
    const newsContent = useSelector((state: RootState) => state.news.latestContent)
    //抓loading
    // const loadingState = useSelector((state: RootState) => state.news.loading)
    useEffect(() => {
        dispatch(fetchNewsLates());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }, [loading]);
    return (<>
        {
            loading ? <Loading></Loading> : null
        }
       
        <AsideNav showIndex={4}></AsideNav>
        <div className='selfContainer'>
            <span className="breadcrumbs">
                <ol className="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/">
                            <span itemProp="name">首頁</span></Link>
                        <meta itemProp="position" content="1" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <span itemProp="name">最新消息</span>
                        <meta itemProp="position" content="2" />
                    </li>
                </ol>
            </span>
            <NewsSection ></NewsSection>
        </div>
        <NewsNav newsContent={newsContent}></NewsNav>
    </>
    )

}
export default NewsIndex


