import { useState } from "react";
import { Link } from "react-router-dom";
import navLogo from '../../frontImage/logo.png'
import brandicon01 from '../../frontImage/brandicon01.svg'
import brandicon03 from '../../frontImage/brandicon03.svg'
import youtube01 from "../../frontImage/youtube01.svg";
import facebook01 from "../../frontImage/facebook01.svg";
const Navigation = ({ title }: { title?: any }) => {
  const iconlink = [
    {
      link: "https://www.facebook.com/TaiwanInternationalDevelopmentCo/",
      src: brandicon01,
      changesrc: facebook01,
      icon: "facebook",
    },
    {
      link: "https://www.youtube.com/@TIDC-Official",
      changesrc: youtube01,
      src: brandicon03,
      icon: "youtube",
    },
  ];
  const [isShowNavMenu, setIsShowNavMenu] = useState(false)
  // toggle 漢堡選單
  const handleClick = () => {
    !isShowNavMenu ? setIsShowNavMenu(true) : setIsShowNavMenu(false)
  }
  return (
    <div className="navMenu" >
      <div className="hamburger" onClick={handleClick}>
        <span className="ham">
          <div className="hamLine topBun"></div>
          <div className="hamLine meat"></div>
          <div className="hamLine bottomBun"></div>
        </span>
        {isShowNavMenu ? <span className="times" >
          <div className="hamLine topChange"></div>
          <div className="hamLine bottomChange"></div>
        </span> : ""}
      </div>
      {isShowNavMenu ?
        <div className="toggleContainer" onClick={handleClick}>
          <div className="toggleContainerBc"></div>
          <div className="toggleContent">
            <div className="toggleContentTxt"><img src={navLogo} alt="Tidc" /></div>
            <ul className="content">
              {title.map((item: any, index: number) => (
                <li className="" key={index}>
                  <div className="labelContainer">
                    <Link
                      to={item.url}
                    >
                      <span >
                        {item.label}
                      </span>
                    </Link>
                  </div>
                  <div className="insideMenuContainer">
                    {item.children.map((item: any, index: number) => (
                      <div key={index} className="insideMenu">
                        <Link
                          to={item.url}
                        >
                          <span >
                            {item.label}
                          </span>
                        </Link>
                      </div>))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <ul className="iconContainer">
            {iconlink.map((val, index) => (
              <li key={index}>
                <a className="socialLink" target="blank" href={val.link}>
                  <img src={val.src} alt={val.icon} />
                  <img className="socialImg" src={val.changesrc} alt={val.icon} />
                </a>
              </li>
            ))}
          </ul>

        </div> : ""}
    </div>

  );
};
export default Navigation;
