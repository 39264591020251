import { getIotMain,getIotContent } from '../../utils/fetchAPI'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface iotState {
    index: string[]
    content: string[]
    loading: boolean
    error: string
}
const initialState = {
    index: [],
    content: [],
    loading: false,
    error: "",
} as iotState

export const fetcIotMain = createAsyncThunk(
    'api/iot', async () => {
        const response = await getIotMain()
        return response.data;
    })
export const fetchIotContent = createAsyncThunk(
    'api/iotContent', async () => {
        const response = await getIotContent()
        return response.data;
    })

export const iotSlice = createSlice({
    name: 'iot',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },

    },
    extraReducers: (builder: any) => {
        builder.addCase(fetcIotMain.pending, (state: iotState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetcIotMain.fulfilled, (state: iotState, action: { payload: any; }) => {
            state.loading = false
            state.index = action.payload.datas;
            return state;
        })

        builder.addCase(fetcIotMain.rejected, (state: iotState, action: { error: { message: any; }; }) => {
            state.loading = false
            return state
        })
        builder.addCase(fetchIotContent.pending, (state: iotState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchIotContent.fulfilled, (state: iotState, action: { payload: any; }) => {
            state.loading = false
            state.content = action.payload.datas;
            return state;
        })
        builder.addCase(fetchIotContent.rejected, (state: iotState, action: { error: { message: any; }; }) => {
            state.loading = false
            state.error = action.error.message
            return state
        })
    }
});

export const iotActions =iotSlice.actions;
export default iotSlice.reducer