import { getHomeNewsLatest, getHomeNewsPin } from '../../utils/fetchAPI'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface homenewsState {
    latest: string[]
    pin: string[]
    loading: boolean
    error: string
}
const initialState = {
    latest: [],
    pin: [],
    loading: false,
    error: ""
} as homenewsState
export const fetchHomeNewsLatest = createAsyncThunk(
    'api/homenews', async () => {
        const response = await getHomeNewsLatest()
        return response.data;
    })
export const fetchHomeNewsPin = createAsyncThunk(
    'api/homenewscontent', async () => {
        const response = await getHomeNewsPin()
        return response.data;
    })

export const homenewsSlice = createSlice({
    name: 'homenews',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(fetchHomeNewsLatest.pending, (state: homenewsState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchHomeNewsLatest.fulfilled, (state: homenewsState, action: { payload: any; }) => {
            state.loading = false
            state.latest = action.payload.datas;
            return state;
        })

        builder.addCase(fetchHomeNewsLatest.rejected, (state: homenewsState, action: { error: { message: any; }; }) => {
            state.loading = false
            state.error = action.error.message
            return state
        })
        builder.addCase(fetchHomeNewsPin.pending, (state: homenewsState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchHomeNewsPin.fulfilled, (state: homenewsState, action: { payload: any; }) => {
            state.loading = false
            state.pin = action.payload.datas;
            return state;
        })

        builder.addCase(fetchHomeNewsPin.rejected, (state: homenewsState, action: { error: { message: any; }; }) => {
            state.loading = false
            state.error = action.error.message
            return state
        })
    }
});


export const homenewsActions = homenewsSlice.actions;
export default homenewsSlice.reducer


