

import { Link } from "react-router-dom";
const ContactLink = ({ className, ...props }: {
    className?: string,
    props?: any
}) => {
    return (
        <div className={className}
            {...props}>
            <div className='asideContact'>
                <div className='asideContactImgContainer'>
                </div>
                <Link to="/contact" ><span className='block' >聯絡我們</span></Link>
                <div className='asideContactArrow'>
                </div>
            </div>
        </div>
    );
};
export default ContactLink;
