const NewsBtn = ({ title, href, className, ...props }: {
  title: string,
  href: string,
  className?: string,
  props?: any
}
) => {
  return (
    <a
      className="newsbtn"
      href={href}
      {...props}
    >
      {title}
    </a>
  );
};
export default NewsBtn;
