import { getHrMain, getHrContent } from '../../utils/fetchAPI'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface hrState {
    index: string[]
    content: string[]
    total: string[]
    loading: boolean
    error: string
}
const initialState = {
    index: [],
    content: [],
    total: [],
    loading: false,
    error: "",
} as hrState

export const fetcHrMain = createAsyncThunk(
    'api/personnal', async () => {
        const response = await getHrMain()
        return response.data;
    })
export const fetchHrContent = createAsyncThunk(
    'api/personnalContent', async () => {
        const response = await getHrContent()
        return response.data;
    })

export const personnalSlice = createSlice({
    name: 'personnal',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },

    },
    extraReducers: (builder: any) => {
        builder.addCase(fetcHrMain.pending, (state: hrState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetcHrMain.fulfilled, (state: hrState, action: { payload: any; }) => {
            state.loading = false
            state.index = action.payload.datas;
            return state;
        })

        builder.addCase(fetcHrMain.rejected, (state: hrState, action: { error: { message: any; }; }) => {
            state.loading = false
            return state
        })
        builder.addCase(fetchHrContent.pending, (state: hrState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchHrContent.fulfilled, (state: hrState, action: { payload: any; }) => {
            state.loading = false
            state.content = action.payload.datas;
            return state;
        })

        builder.addCase(fetchHrContent.rejected, (state: hrState, action: { error: { message: any; }; }) => {
            state.loading = false
            state.error = action.error.message
            return state
        })
    }
});

export const personnalActions = personnalSlice.actions;
export default personnalSlice.reducer