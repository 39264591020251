import { useState, useEffect, useRef } from "react";
import ContactLink from "components/UI/ContactLink";
import { combinTable } from '../../utils/dataHandling'

const TimeLine = ({ timeline, timelineContent, latesId }: { timeline: any[], timelineContent: string[], latesId: any }) => {
  //判斷是否打開對應的內容
  let timelineRef = useRef<HTMLDivElement>(null)
  const showContent = (newId: number) => {
    // 防止手機版 點開對應的year 不會滑到對的資訊位置
    const node = timelineRef.current
    node !== null && window.scrollTo({ behavior: 'smooth', top: node.offsetHeight })
    // 以下才是toggle fun
    switch (true) {
      case isToogle && prevDataId === undefined && newId !== firstId:
        setPrevDataId(currentId);
        setCurrentId(newId);
        break;
      case isToogle && prevDataId !== currentId && prevDataId === undefined:
        setCurrentId(newId);
        setIsToogle(false)
        setPrevDataId(currentId)
        break;
      case isToogle && newId === currentId:
        setCurrentId(newId);
        setIsToogle(false)
        setPrevDataId(currentId)
        break;
      default:
        setIsToogle(true);
        setCurrentId(newId);
        break;
    }
  };
  const [total, settotal] = useState<any[] | undefined | void>()

  useEffect(() => {
    const main = [...timeline]
    const content = [...timelineContent]
    const firstId = { ...latesId }
    showContent(firstId.id)
    setIsToogle(true)
    setFirstId(firstId.id)
    setCurrentId(firstId.id)
    setPrevDataId(undefined)

    if (timeline && timelineContent) {
      return settotal(combinTable(main, content))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latesId, timeline, timelineContent])
  const [isToogle, setIsToogle] = useState(false);
  const [prevDataId, setPrevDataId] = useState<number | undefined>(-1);
  const [currentId, setCurrentId] = useState<number | undefined>(-1);
  const [firstId, setFirstId] = useState<number | undefined>(-1);
  return (
    <div className="aboutHistory">
      <div className="title">
        <span className="titleEn">TIDC Time line</span>
        <h3 className="titlech">沿革時間軸</h3>
      </div>
      <div className="timeLineContainer">
        {total && total.map((val) => (
          <div className="container" key={val.id}>
            <div className="titleContainer" onClick={() => showContent(val.id)}>
              <span className="year" style={{ color: isToogle && val.id === currentId ? "" : "black" }}>{val.year}</span>
              <div className="circle" style={{ transform: isToogle && val.id === currentId ? "rotate(0deg)" : "" }}>
              </div>
            </div>
            <span >
              {/* 控制是否顯示圖片 */}
              {isToogle && currentId === val.id ? (
                <div
                  data-aos="fade-down"
                  data-aos-once="true"
                  data-aos-duration="1500"
                  className="content"
                  ref={timelineRef}
                >
                  <div className="contentContainer" >
                    <ul>
                      {val.dataList.map((item: { id: number; content: string; }) => (<li key={item.id} className="text">{item.content}</li>))}
                    </ul>
                  </div>
                  <div className="imgContainer" style={{ display: val.image_src.length === 0 || val.image_src === undefined || val.image_src === null ? "none" : "" }}>
                    <div className="img">
                      <img src={val.image_src} alt={val.image_name} />
                    </div>
                  </div>
                </div>
              ) : null}
            </span>
          </div>
        ))}
      </div>
      <ContactLink className='downContactContainer'></ContactLink>
    </div>
  );
};
export default TimeLine;
