import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getNewsPrev,getNewsNext } from '../../utils/fetchAPI'

interface newsState {
    prev: any[]
    next: any[]
    loading: boolean
    error: string
}
const initialState = {
    prev: [],
    next: [],
    loading: false,
    error: "",
} as newsState
export const fetchNewsPrev = createAsyncThunk(
    'api/newsprev', async (val: string| undefined) => {
        const response = await getNewsPrev(val)
        return response.data;
    })
export const fetchNewsNext = createAsyncThunk(
    'api/newsnext', async (val: string| undefined) => {
        const response = await getNewsNext(val)
        return response.data;
    })

const newsNextPrevSlice = createSlice({
    name: 'newsNextPrev',
    initialState,
    reducers: {
    },
    extraReducers: (builder: any) => {
        //fetchNewsPrev
        builder.addCase(fetchNewsPrev.pending, (state: newsState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchNewsPrev.fulfilled, (state: newsState, action: { payload: any; }) => {
            state.loading = false
            state.prev = action.payload.datas
            return state;

        })
        builder.addCase(fetchNewsPrev.rejected, (state: newsState, action: { error: { message: any; } }) => {
            state.loading = false
            state.error = action.error.message
            return state
        })
         //fetchNewsNext
        builder.addCase(fetchNewsNext.pending, (state: newsState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchNewsNext.fulfilled, (state: newsState, action: { payload: any; }) => {
            state.loading = false
            state.next = action.payload.datas
            return state;

        })
        builder.addCase(fetchNewsNext.rejected, (state: newsState, action: { error: { message: any; } }) => {
            state.loading = false
            state.error = action.error.message
            return state
        })

    }

})
export const newsrActions = newsNextPrevSlice.actions;



export default newsNextPrevSlice.reducer



