
export const combinTable = (mainTable: any[], appendix: any[]) => {
    let i: number
    let j: number
    for (i = 0; i < mainTable.length; i++) {
        mainTable[i] = { ...mainTable[i], dataList: [] }
        for (j = 0; j < appendix.length; j++) {
            switch (true) {
                //由於不同的主附表組合都會有對應的欄位名稱，以此方式去抓對應的副表

                //關於我們 -> tidc沿革 -> 時間軸的主附表
                case Object.keys(appendix[j]).includes("timeline_id"):
                    if (
                        appendix[j].timeline_id === mainTable[i].id
                    ) {
                        mainTable[i].dataList.push(appendix[j])
                    }
                    break;
                //關於我們 -> tidc team ->主附表
                case Object.keys(appendix[j]).includes("team_id"):
                    if (
                        appendix[j].team_id === mainTable[i].id
                    ) {
                        mainTable[i].dataList.push(appendix[j])
                    }
                    break;
                //關於我們 -> tidc team ->主附表
                case Object.keys(appendix[j]).includes("human_resources_id"):
                    if (
                        appendix[j].human_resources_id === mainTable[i].id
                    ) {
                        mainTable[i].dataList.push(appendix[j])
                    }
                    break;
                //關於我們 -> tidc team ->主附表
                case Object.keys(appendix[j]).includes("smart_parking_planning_id"):
                    if (
                        appendix[j].smart_parking_planning_id === mainTable[i].id
                    ) {
                        mainTable[i].dataList.push(appendix[j])
                    }
                    break;
                //關於我們 -> tidc team ->主附表
                case Object.keys(appendix[j]).includes("information_service_id"):
                    if (
                        appendix[j].information_service_id === mainTable[i].id
                    ) {
                        mainTable[i].dataList.push(appendix[j])
                    }
                    break;
                //關於我們 -> tidc team ->主附表
                case Object.keys(appendix[j]).includes("partners_id"):
                    if (
                        appendix[j].partners_id === mainTable[i].id
                    ) {
                        mainTable[i].dataList.push(appendix[j])
                    }
                    break;
            }

        }
    }
    return mainTable
}
