import { forwardRef } from 'react'

interface Props {
  label?: string,
  name: string,
  placeholder?: string,
  className?: string,
  inputClassName?: string,
  labelClassName?: string,
  required?: boolean,
  error?: object,
  noLabel?: boolean,
  disabled?: boolean,
  defaultValue?: string,
  value?: string,
  maxlength?:any,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const InputField = forwardRef<HTMLInputElement, Props>(
  ({
    name,
    label,
    onChange,
    onBlur,
    className,
    inputClassName,
    labelClassName,
    defaultValue,
    required,
    placeholder,
    error,
    maxlength,
    noLabel = false,
    disabled = false,
    value
  }: Props, ref) => {

    return <div className={`inputField ${className}`}>

      {
        !noLabel && <>
             <span className="formcontentspan">*</span>
          {
            label && <label htmlFor={label} className={
              ` ${labelClassName}`}>
              {`${label}\u00A0\u00A0|\u00A0\u00A0\u00A0`}
            </label>
          }
        </>
      }

      <input
        ref={ref}
        disabled={disabled}
        name={name}
        type="text"
        defaultValue={defaultValue}
        placeholder={placeholder}
        value={value}
        maxLength={maxlength}
        required={required}
        id={label}
        className={
          `field ${inputClassName}`
        }
        onChange={onChange}
        onBlur={onBlur} />
    </div>;
  }
)
export default InputField;