import servicespace01 from "../../frontImage/servicespace01.svg";
import servicespace03 from "../../frontImage/serviceSpacee03.png";
import servicespace04 from "../../frontImage/serviceSpacee04.svg";

const ServiceSpaceMain = () => {
    const ServiceSpaceMainList = [
        {
            icon: servicespace01,
            iconName: 'disadvantage',
            title: '常見場域服務缺口',
            content: ["場域空間迷思", "路邊停車管理勞務成本高昂", "缺乏完整的服務規劃"]
        },
        {
            icon: servicespace04,
            iconName: 'advantage',
            title: '創新服務解決方案',
            content: ["完整的人車動線引導、友善介面設計", "規劃智慧停車科技應用，提升人員效率", "針對委託場域特性規劃完整服務流程"]
        },

    ];
    return (
        <>
            <div className="serviceSpaceeMain" data-aos="fade-down"
                data-aos-duration="1000">
                <div className="container" >
                    <div className="content"  >
                        {ServiceSpaceMainList.map((val: any, index: number) => (
                            <div key={index} className="contentContainer" data-aos="fade-down"
                                data-aos-duration="1200">
                                <span className="title">{val.title}</span>
                                <ul className="contentList">
                                    {
                                        val.content.map((val: any, index: number) => (<li key={index}>{val}</li>))
                                    }

                                </ul>
                            </div>

                        ))}
                    </div>
                    <div className="arrow" data-aos="fade-down"
                        data-aos-duration="1500">
                    </div>
                    <div className="mainVisualImg" data-aos="zoom-out-down"
                        data-aos-duration="1500">
                        <img src={servicespace03} alt="場域規劃主視覺" />
                    </div>
                </div>


            </div>
        </>
    );
};
export default ServiceSpaceMain;