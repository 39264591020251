import AsideNav from '../../components/mainLayout/AsideNav'
import NewsMainCard from '../../components/newsMain/NewsMainCard'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { fetchNews } from 'store/news/frontNews'
import { useDispatch } from 'react-redux'
import Loading from 'components/mainLayout/Loading'
const NewsMedia = () => {

    const dispatch = useDispatch<any>();
    //抓loading
    //   const loadingState = useSelector((state: RootState) => state.news.loading)
    useEffect(() => {
        dispatch(fetchNews({ disabled: 'false', label_name: '媒體報導', desc: `${"is_featured"},${"date"}` }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }, [loading]);
    return (<>
        {
            loading ? <Loading></Loading> : null
        }
        <Helmet>
            <title>最新消息-媒體報導｜TIDC台灣國際-智慧停車領航者</title>
        </Helmet>
        <AsideNav showIndex={4}></AsideNav>
        <div className='selfContainer'>
            <span className="breadcrumbs">
                <ol className="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/">
                            <span itemProp="name">首頁</span></Link>
                        <meta itemProp="position" content="1" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/news">
                            <span itemProp="name">最新消息</span></Link>
                        <meta itemProp="position" content="2" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <span itemProp="name">媒體報導</span>
                        <meta itemProp="position" content="3" />
                    </li>
                </ol>
            </span>
            <NewsMainCard></NewsMainCard>
        </div>
    </>
    )

}
export default NewsMedia


