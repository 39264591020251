import AsideNav from '../../components/mainLayout/AsideNav'
import AwardCard from '../../components/awardMain/AwardCard'
import { Link } from 'react-router-dom'
import Breadcrumbs from "../../components/UI/Breadcrum";
import ContactLink from 'components/UI/ContactLink';
import { Helmet } from 'react-helmet-async';


const AwardMain = () => {
    return <>
        <Helmet>
            <title>各界肯定｜TIDC台灣國際-智慧停車領航者</title>
        </Helmet>
        <AsideNav showIndex={2}></AsideNav>
        <Breadcrumbs></Breadcrumbs>

        <div className='selfContainer'>
            <div className="breadcrumbs">
                <ol itemScope itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/">
                            <span itemProp="name">首頁</span></Link>
                        <meta itemProp="position" content="1" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <span itemProp="name">各界肯定</span>
                        <meta itemProp="position" content="2" />
                    </li>
                </ol>
            </div>
            <AwardCard></AwardCard>
            <ContactLink className='downContactContainer mt-40'></ContactLink>
        </div>

    </>;

}
export default AwardMain