
import LinkBtn from '../UI/LeftLinkBtn';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Key, useEffect, useState } from "react";
import ContactLink from "components/UI/ContactLink";
import { combinTable } from "utils/dataHandling";
const ServiceSpaceSection = ({ iot, iotContent }: { iot: string[], iotContent: string[] }) => {
    const [total, settotal] = useState<any[] | undefined | void>()
    useEffect(() => {
        const main = [...iot]
        const content = [...iotContent]
        if (iot && iotContent) {
            return settotal(combinTable(main, content))
        }
    }, [iot, iotContent])
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };
    return (
        <>
            <div className="serviceSpaceSection">

                {total && total.map((val: any) => (
                    <div key={val.id} className="container " data-aos="fade-down"
                    data-aos-duration="1000">
                        <div className="content" data-aos="fade-down"
                            data-aos-duration="1000">
                            <div className="titleContainer">
                                <div className="iconContainer"><img src={val.icon_src} alt={val.title} /></div>
                                <h4>{val.title}</h4>
                            </div>
                            <div className="contentContainer"  data-aos="fade-down"
                            data-aos-duration="1500">
                                <p className="contentp">
                                    {val.content}
                                </p>
                                <br />
                                <p className="detail">
                                    {`合作案例\u00A0\u00A0:\u00A0\u00A0${val.company}`}
                                </p>
                            </div>
                            {
                                val.link_url === null || (val.link_url).length <= 0 || val.link_url === undefined ? null : <div className="linkContainer">
                                    <LinkBtn title={val.link_title} href={val.link_url}></LinkBtn>
                                </div>
                            }
                        </div>
                        <Slider
                            className='carouselContainer'
                            {...settings}
                        >
                            {val.dataList.map((val: { id: Key | null | undefined; image_src: any; image_name: string | undefined; }) => {
                                return (
                                    <div key={val.id} >

                                        <img src={`${val.image_src}`} alt={val.image_name} />

                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                ))}
            </div>
            <ContactLink className='downContactContainer'></ContactLink>

        </>
    );
};
export default ServiceSpaceSection;