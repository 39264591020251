import { postContact, postNoReplyEmail, getNoReplyToken } from '../../utils/fetchAPI'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface contactState {
    messageList: string[]
    noreplyMail: any
    admin_token: string
    loading: boolean
    error: string
}
const initialState = {
    messageList: [],
    noreplyMail: {},
    admin_token: "",
    loading: false,
    error: '',
} as contactState
export const postMessage = createAsyncThunk(
    'api/message/send', async (val: object) => {
        try {
            const response = await postContact(val);
            return response.data;
        } catch (err) {
            console.error(err)
        }
    })
export const POST_NOREPLY = 'POST_NOREPLY';
export const postNoReplyMail = createAsyncThunk(
    'api/no-reply-mail/send', async (val: object) => {
        try {
            const response = await postNoReplyEmail(val);
            return response.data;
        } catch (err) {
            console.error(err)
        }
        return { type: POST_NOREPLY, val };
    })


export const fetchNoReplyToken = createAsyncThunk(
    'api/no-reply-mail/admin_token', async () => {
        try {
            const response = await getNoReplyToken();
            return response.data;
        } catch (err) {
            console.error(err)
        }

    })

export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(postMessage.pending, (state: contactState) => {
            state.loading = true
            return state;
        })
        builder.addCase(postMessage.fulfilled, (state: contactState, action: { payload: any; }) => {
            state.loading = false
            state.messageList = action.payload.datas;
            return state;
        })

        builder.addCase(postMessage.rejected, (state: contactState, action: { error: { message: any; }; }) => {
            state.loading = false
            state.error = action.error.message
            return state
        })
        //
        builder.addCase(postNoReplyMail.pending, (state: contactState) => {
            state.loading = true
            return state;
        })
        builder.addCase(postNoReplyMail.fulfilled, (state: contactState, action: { payload: any; }) => {
            state.loading = false
            state.messageList = action.payload.datas;
            return state;
        })

        builder.addCase(postNoReplyMail.rejected, (state: contactState, action: { error: { message: any; }; }) => {
            state.loading = false
            state.error = action.error.message
            return state
        })
        // get admin_token
        builder.addCase(fetchNoReplyToken.pending, (state: contactState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchNoReplyToken.fulfilled, (state: contactState, action: { payload: any }) => {
            state.loading = false
            state.admin_token = action.payload.Content;
            return state;
        })
        builder.addCase(fetchNoReplyToken.rejected, (state: contactState, action: { error: { message: any; } }) => {
            state.loading = false
            state.error = action.error.message
            return state
        })
    }
});


export const contactActions = contactSlice.actions;
export default contactSlice.reducer