import CookieConsent from "react-cookie-consent";

const Cookie = () => {
  return (
    //expires={150} === cookie 保留150天
    <div className="cookie">
      <CookieConsent
        buttonText="我同意"
        style={{ background: "#727171", right: '0' }}
        buttonStyle={{ borderRadius: '8px', }}
        cookieName="tidcCookie"
        expires={150}
      >
        我們紀錄 cookie
        資訊，以提供客製化內容，可優化您的使用體驗，若繼續閱覽本網站內容，即表示您同意我們使用
        cookies。
      </CookieConsent>
    </div>
  );
};
export default Cookie;
