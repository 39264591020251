import { useState, useMemo, SetStateAction } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "../../store/index";
import { Link, NavLink } from "react-router-dom";
import Pagination from "components/UI/Pagination";

const NewsMainCard = () => {
  const news = useSelector((state: RootState) => state.news.content);
  // 針對點選後樣式設置兩個不同的控制器，因為這邊沒有用回圈跑
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [conditionFirst, setConditionFirst] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [conditionSec, setConditionSec] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;

  const newsClone = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return news.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, news]);
  return (
    <>
      <div className="newsMainCard">
        <div className="container">
          <div className="bookmarkContainer">
            <NavLink
              to="/news/company"
              className={({ isActive }) => `bookmark first ${isActive && 'focustyle'}`}
            >
              企業快訊
            </NavLink>
            <NavLink
              to="/news/media"
              className={({ isActive }) => `bookmark second ${isActive && 'focustyle'}`}
            >
              媒體報導
            </NavLink>
          </div>
          <div className="contentContainer">
            {news &&
              newsClone.map((val: any) => (
                <Link
                  className="containerCard"
                  data-aos="fade-down"
                  data-aos-duration="1500"
                  key={val.id}
                  to={`/news/${val.id}`}
                >
                  {val.is_featured === true ? (
                    <div className="pinImage"></div>
                  ) : null}
                  <div className="imgContainer">
                    <img src={val.image_src} alt={val.image_alt} />
                  </div>
                  <div className="content">
                    <h3>
                      {val.title.trim().length > 29
                        ? val.title.substring(0, 29) + "..."
                        : val.title}
                    </h3>
                    <span className="date">{val.date}</span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: val.content.replaceAll(/(<([^>]+)>)/ig, '').substring(0, 60) + "...",
                      }}

                    ></p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={news.length}
          pageSize={pageSize}
          onPageChange={(page: SetStateAction<number>) => setCurrentPage(page)}
        ></Pagination>
      </div>
    </>
  );
};
export default NewsMainCard;

