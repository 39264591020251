import Axios from "axios";
const CAROUSEL_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/carousel_map';
const COUNTER_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/counter';
//ABOUT
const TIMELINE_MAIN_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/timeline';
const TIMELINE_CONTENT_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/timeline_list';
const TEAM_MAIN_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/teams';
const TEAM_CONTENT_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/teams_list';
const CORP_MAIN_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/partners';
const CORP_CONTENT_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/partners_list';

//SERVICE
const INTER_MAIN_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/information_services';
const INTER_CONTENT_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/information_service_list';
const IOT_MAIN_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/smart_parking_planning';
const IOT_CONTENT_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/smart_parking_planning_carousel';
//NEWS
const NEWS_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/news';
//AWARD
// 下方的主表尚未使用到，未來會規劃獎盃可以更新（此張表已存在資料庫）
// const AWARD_MAIN_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/awards';
const AWARD_CONTENT_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/awards_list';
//HIRE
const HR_MAIN_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/human_resources';
const HR_CONTENT_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/human_resource_text';
//CONTACT
const CONTACT_URL = 'https://wkzshe1wa9.execute-api.ap-southeast-1.amazonaws.com/default/official_web_site/contacts';
const NOREPLY_MAIL_URL = 'https://6ezncucemg.execute-api.ap-southeast-1.amazonaws.com/aws/send-email';
const ADMIN_TOKEN_URL = 'https://jmyljyxy4d.execute-api.ap-southeast-1.amazonaws.com/aws/TIDCApi/Login?Account=test01&Password=test001';

async function getAPI(endpoint: string, params: object) {
  return Axios({
    method: 'GET',
    params: params,
    url: endpoint,
    headers: {
      'content-type': 'application/json'
    },

  })
}
async function postAPI(endpoint: string, data: object) {
  return Axios({
    method: 'POST',
    url: endpoint,
    headers: {
      'content-type': 'application/json'
    },
    data: { "datas": [data] },
  })
}
// for no-reply mail cause it is not the orignal api for here
async function postNoReplyAPI(endpoint: string, data?: object) {
  return Axios({
    method: 'POST',
    url: endpoint,
    headers: {
      'content-type': 'text/plain'
    },
    data: data,
  })
}
// 共打20次  6個要跑回圈 （都跑2維）
//首頁
export async function getCarousel() {
  return getAPI(CAROUSEL_URL, { disabled: 'false', asc: "id" });
}
export async function getCounter() {
  return getAPI(COUNTER_URL, { asc: "id" });
}
//about
export async function getTimeLineMain() {
  return getAPI(TIMELINE_MAIN_URL, { asc: "id" });
}
export async function getTimeLineContent() {
  return getAPI(TIMELINE_CONTENT_URL, { asc: "id" });
}
export async function getTeamMain() {
  return getAPI(TEAM_MAIN_URL, { asc: "id" });
}
export async function getTeamContent() {
  return getAPI(TEAM_CONTENT_URL, { asc: "id" });
}
//合作夥伴要打四次因為分類
export async function getCorpMain() {
  return getAPI(CORP_MAIN_URL, { asc: "id" });
}
export async function getCorpContent() {
  return getAPI(CORP_CONTENT_URL, { asc: "id" });
}
//service
export async function getInterMain() {
  return getAPI(INTER_MAIN_URL, { asc: "id" });
}
export async function getInterContent() {
  return getAPI(INTER_CONTENT_URL, { asc: "id" });
}
export async function getIotMain() {
  return getAPI(IOT_MAIN_URL, { asc: "id" });
}
export async function getIotContent() {
  return getAPI(IOT_CONTENT_URL, { asc: "id" });
}
//new
//home
//取得最新文章
export async function getHomeNewsLatest() {
  return getAPI(NEWS_URL, { disabled: false, desc: "date", limit: 2 });
}
//取得精選
export async function getHomeNewsPin() {
  return getAPI(NEWS_URL, { is_featured: true, disabled: false, desc: "date", limit: 6 });
}
// 頁籤要處理
export async function getNews(val: object) {
  return getAPI(NEWS_URL, val);
}
//取得最新文章
export async function getNewsLatest() {
  return getAPI(NEWS_URL, { disabled: false, desc: "date", limit: 3 });
}
//取得精選
export async function getNewsPin() {
  return getAPI(NEWS_URL, { is_featured: true, asc: "date", limit: 2 });
}
//取得文章內容
export async function getNewsid(val: object) {
  return getAPI(NEWS_URL, val);
}

//取得上一則
// where diabled = false AND id < ${ id } LIMIT 1
export async function getNewsPrev(val: string | undefined) {
  return getAPI(NEWS_URL, { disabled: false, limit: 1, operators: `id < ${val}`, desc: "id", });
}
//取得下一則
export async function getNewsNext(val: string | undefined) {
  return getAPI(NEWS_URL, { disabled: false, limit: 1, operators: `id > ${val}`, asc: "id", });
}
//award
// export async function getAwardMain() {
//   return getAPI(AWARD_MAIN_URL, { asc: "id" });
// }

export async function getAwardContent(val: object) {
  return getAPI(AWARD_CONTENT_URL, val);
}

//hire
export async function getHrMain() {
  return getAPI(HR_MAIN_URL, { asc: "id" });
}
export async function getHrContent() {
  return getAPI(HR_CONTENT_URL, { asc: "id" });
}
//contact
export async function postContact(val: object) {
  return postAPI(CONTACT_URL, val);
}
// 寄送no reply
export async function postNoReplyEmail(val: object) {
  return postNoReplyAPI(NOREPLY_MAIL_URL, val);
}
//拿到no reply 的 admin_token
export async function getNoReplyToken() {
  return postNoReplyAPI(ADMIN_TOKEN_URL);
}