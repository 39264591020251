import { useNavigate } from "react-router-dom";
import award_torphy01 from '../../frontImage/award_torphy01.png'
import award_torphy02 from '../../frontImage/award_torphy02.png'
import award_torphy03 from '../../frontImage/award_torphy03.png'
import award_torphy04 from '../../frontImage/award_torphy04.png'
import award_cor01 from '../../frontImage/award_cor01.png'
import award_cor02 from '../../frontImage/award_cor02.png'
import award_cor03 from '../../frontImage/award_cor03.png'
import award_cor04 from '../../frontImage/award_cor04.png'

const AwardCard = () => {
   //未來此處可修改成動態資料，目前是寫死的
    const navigate = useNavigate();
    const awardMainList = [
        {
            label: "金擘獎",
            trophy: award_torphy03,
            corCompanyName: '中華民國經濟部',
            corCompany: award_cor01,
            link:'/award/gta',
            content: "「金擘獎」為促參界的最高榮譽，素有「臺灣促參界奧斯卡」的美名。為了鼓勵民間參與公共建設、提升推動成效，財政部推動促參司於2002年設立民間參與公共建設金擘獎 獎勵提供優質公共服務的民間機構，激勵企業單位持續參與公共建設的推動。"
        },
        {
            label: "國家卓越建設獎",
            trophy: award_torphy02,
            corCompanyName: '國家卓越建設獎',
            corCompany: award_cor02,
            link:'/award/fiabci',
            content: "「國家卓越建設獎」是目前國內建設獎項中最重要的獎項之一，也是國內唯一與國際接軌「建築界最高的榮譽獎項」。此獎項除了肯定獲獎者對臺灣建設的付出與貢獻，也期盼與企業單位共同打造符合社會需求，代表臺灣在地的人本建築。"
        },
        {
            label: "雲端創新獎",
            trophy: award_torphy04,
            corCompanyName: '雲端創新獎',
            corCompany: award_cor03,
            link:'/award/cloudaward',
            content: "「雲端物聯網創新獎」設立的目的在於加速國內雲端物聯網產業的發展，並彰顯政府部門與民間企業在相關領域共同努力的成果，希望能夠對相關產業產生示範作用，提升產業內的技術水準，帶動產業的整體發展。"
        },
        {
            label: "服務品質獎",
            trophy: award_torphy01,
            corCompanyName: '服務品質獎',
            corCompany: award_cor04,
            link:'/award/servicequality',
            content: "「服務品質獎」為交通局自2016年起定期辦理停車場服務品質評鑑考評，提供民眾優良的停車環境及服務品質，並以「履約情形、環境品質、巡場管理、民眾滿意度」四大指標作為評分項目，表揚評鑑優等的停車場業者。"
        },
    ]
    return (<>
        <div className="awardMain">
            <div className='container'>
                {awardMainList.map((val, index) => (<div key={index} className='contentContainer' data-aos="fade-down"
                        data-aos-duration="1500" onClick={() => navigate(val.link)}>
                    <div className='title'>
                        <div className='trophy'><img src={val.trophy} alt={val.label} /></div>
                        <div className='titleContainer'>
                            <h4>{val.label}</h4>
                            <div className='imgContainer'>
                                <img src={val.corCompany} alt={val.corCompanyName} />
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <p>{val.content}</p>
                    </div>
                </div>))}
            </div>
        </div>
    </>
    )

}
export default AwardCard