import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { fetchcorpContent, fetchcorpMain } from 'store/about/corp'
import AboutCopoSection from '../../components/aboutMain/AboutCopoSection'
import Breadcrumbs from 'components/UI/Breadcrum'
import AsideNav from '../../components/mainLayout/AsideNav'
import Loading from 'components/mainLayout/Loading'


const AboutCopo = () => {
  const dispatch = useDispatch<any>();
  // 需要拿到的資料
  const corp = useSelector((state: RootState) => state.corp.index);
  const corpContent = useSelector((state: RootState) => state.corp.content)

  useEffect(() => {
    dispatch(fetchcorpMain());
    dispatch(fetchcorpContent());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [loading]);
  //抓loading
  // const loadingState = useSelector((state: RootState) => state.corp.loading)
  return <>
    {
      loading === false && loading ? <Loading></Loading> : null
    }
    <Helmet>
      <title>關於我們-合作夥伴｜TIDC台灣國際-智慧停車領航者</title>
    </Helmet>
    <AsideNav showIndex={0}></AsideNav>
    <Breadcrumbs></Breadcrumbs>
    <div className='selfContainer'>
      <div className="breadcrumbs">
        <ol itemScope itemType="https://schema.org/BreadcrumbList">
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <Link itemProp="item" to="/">
              <span itemProp="name">首頁</span></Link>
            <meta itemProp="position" content="1" />
          </li>
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <Link itemProp="item" to="/about">
              <span itemProp="name">關於我們</span></Link>
            <meta itemProp="position" content="2" />
          </li>
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <span itemProp="name">TIDC 合作夥伴</span>
            <meta itemProp="position" content="3" />
          </li>
        </ol>
      </div>
      <AboutCopoSection corp={corp} corpContent={corpContent}></AboutCopoSection>

    </div>
  </>;

}
export default AboutCopo