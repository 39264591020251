import homeabout01 from "../../frontImage/homeabout01.png";
import homeaboutIcon01 from "../../frontImage/homeaboutIcon01.svg";
import homeaboutIcon02 from "../../frontImage/homeaboutIcon02.svg";
import homeaboutIcon03 from "../../frontImage/homeaboutIcon03.svg";
import homeabout05 from "../../frontImage/homeabout05.png";
import ReactCompareImage from "react-compare-image";
import { useState, useEffect } from "react";
import InternalLink from "components/UI/ InternalLink";
import improve1_before from "../../frontImage/improve1_before.jpg";
import improve1_after from "../../frontImage/improve1_after.jpg";
import improve2_before from "../../frontImage/improve2_before.jpg";
import improve2_after from "../../frontImage/improve2_after.jpg";
import improve3_before from "../../frontImage/improve3_before.jpg";
import improve3_after from "../../frontImage/improve3_after.jpg";
import improve4_before from "../../frontImage/improve4_before.jpg";
import improve4_after from "../../frontImage/improve4_after.jpg";
import improve5_before from "../../frontImage/improve5_before.jpg";
import improve5_after from "../../frontImage/improve5_after.jpg";
import improve6_before from "../../frontImage/improve6_before.jpg";
import improve6_after from "../../frontImage/improve6_after.jpg";
import React from "react";

const HomeAbout = () => {
  // 首頁的對比圖資料變更
  // 600px *450px 建議
  const selectList = [
    {
      id: 1,
      title: "出入口",
      img: [
        {
          src: improve1_before,
          alt: "出入口改造前",
        },
        {
          src: improve1_after,
          alt: "出入口改造後",
        },
      ],
    },
    {
      id: 2,
      title: "梯廳",
      img: [
        {
          src: improve2_before,
          alt: "梯廳改造前",
        },
        {
          src: improve2_after,
          alt: "梯廳改造後",
        },
      ],
    },
    {
      id: 3,
      title: "迎賓意象",
      img: [
        {
          src: improve3_before,
          alt: "機車停車改造前",
        },
        {
          src: improve3_after,
          alt: "機車停車改造後",
        },
      ],
    },
    {
      id: 4,
      title: "充電區",
      img: [
        {
          src: improve4_before,
          alt: "充電區改造前",
        },
        {
          src: improve4_after,
          alt: "充電區改造後",
        },
      ],
    },
    {
      id: 5,
      title: "服務中心",
      img: [
        {
          src: improve5_before,
          alt: "服務中心改造前",
        },
        {
          src: improve5_after,
          alt: "服務中心改造後",
        },
      ],
    },
    {
      id: 6,
      title: "車道空間",
      img: [
        {
          src: improve6_before,
          alt: "車道空間改造前",
        },
        {
          src: improve6_after,
          alt: "車道空間改造後",
        },
      ],
    },
  ];
  const [filterId, setFilterId] = useState<number | undefined>(1);
  const [beforeImg, setBeforeImg] = useState<any | undefined>("");
  const [afterImg, setAfterImg] = useState<any | undefined>("");

  const getDataClick = (categoryId: any) => {
    setFilterId(categoryId);
  };
  useEffect(() => {
    const tData = selectList.filter(
      (val: { id: number }) => val.id === filterId
    );
    setBeforeImg(tData[0].img[0].src);
    setAfterImg(tData[0].img[1].src);
  }, [filterId]);

  return (
    <div className="HomeAbout">
      {/* 第一層 */}
      <div
        className="HomeAboutontainer"
      >
        <div className="HomeAboutSec">
          <div className="HomeAboutTitleCon" data-aos="fade-down"
            data-aos-duration="1000">
            <div className="flex HomeAboutIcon">
              <img src={homeaboutIcon01} alt="停車服務" />
            </div>
            <h2 className="HomeAboutTitle ">智慧停車比你想得更細緻</h2>
          </div>
          <p className="HomeAboutContent" data-aos="fade-down"
            data-aos-duration="1200">
            台灣國際擅長從偌大的場域中發現服務缺口、營運痛點，以及服務流程的可優化處，進而提出創新服務的解決方案，針對場域特性規畫完整的服務流程、設計友善的人車動線引導、介面指示，並將科技妥善地運用於智慧停車的服務，最大化提升人員效率。
          </p>

          <div className="HomeAboutContentImg" data-aos="fade-up"
            data-aos-duration="1200">
            <img src={homeabout01} alt="創新服務" />
          </div>
          <div className="homeAboutLinkCon" data-aos="fade-up"
            data-aos-duration="1500">
            <InternalLink to="/about" title="關於我們"></InternalLink>
          </div>
        </div>
        <div
          className="HomeAboutImgContainer"
          data-aos="fade-down"
          data-aos-duration="1800"
        >
          <div className="HomeAboutImgBc"></div>
          <div className="HomeAboutImg"></div>
        </div>
      </div>
      {/* 第二層 */}
      <div
        className="HomeSecAboutontainer  "

      >
        <div className="HomeAboutSec">
          <div className="HomeAboutTitleCon" data-aos="fade-down"
            data-aos-duration="1000">
            <div className="flex HomeAboutIcon">
              <img src={homeaboutIcon03} alt="停車服務" />
            </div>
            <h2 className="HomeAboutTitle" >場內改善比較</h2>
          </div>
          <p className="HomeAboutContent" data-aos="fade-down"
            data-aos-duration="1000" >
            全台首創以人文、科技、環保、兼具美學的概念，作為智慧停車場域的規劃與設計理念，致力於協助全台非專業經營的停車場域，擺脫傳統停車場的不良印象，提供使用者一個舒適的停車空間！
          </p>
          <div className="contentContainer" >
            {
              <div className="HomeAboutselec" data-aos="fade-down"
                data-aos-duration="1200">
                {selectList.map((val: any) => {
                  return (
                    <React.Fragment key={val.id}>
                      <div
                        className={`selecItem ${filterId === val.id ? "focusSelecItem" : ""
                          }`}
                        onClick={() => getDataClick(val.id)}
                      >
                        <span>{val.title}</span>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            }
          </div>
          <div className="HomeAboutContentImg" data-aos="fade-up"
            data-aos-duration="1200">
            <ReactCompareImage
              leftImageAlt="改善前圖片"
              leftImageLabel="before"
              leftImage={beforeImg}
              rightImageAlt="改善後圖片"
              rightImageLabel="after"
              rightImage={afterImg}
              sliderPositionPercentage={0.5}
            />
          </div>
          <div className="homeAboutLinkCon" data-aos="fade-up"
              data-aos-duration="1500">
            <InternalLink
              to="/service/smart-parking-solution"
              title="智慧停車規劃"
            ></InternalLink>
          </div>
        </div>
        <div
          className="HomeAboutImgContainer"
          data-aos="fade-down"
          data-aos-duration="1800"
        >
          <div className="HomeAboutImgBc"></div>
          <div className="HomeAboutImg"></div>
        </div>
      </div>
      {/* 第三層 */}
      <div
        className="HomethirdAboutontainer"

      >
        <div
          className="HomeAboutSec"

        >
          <div className="HomeAboutTitleCon" data-aos="fade-down"
            data-aos-duration="1000">
            <div className="flex HomeAboutIcon" >
              <img src={homeaboutIcon02} alt="停車服務" />
            </div>
            <h2 className="HomeAboutTitle ">資訊整合與線上服務</h2>
          </div>
          <p className="HomeAboutContent" data-aos="fade-down"
            data-aos-duration="1000">
            專注於智慧停車領域，致力於創新研發，強調停車大數據的整合、軟體研發的技術。
            <br />
            開發人性化且易操作的停車管理系統，從停車場域落實服務應用，將數據同步回饋至交通、民眾應用端，轉化科技應用營造優質的數位服務體驗。
          </p>

          <div className="HomeAboutContentImg" data-aos="fade-up"
            data-aos-duration="1200">
            <img src={homeabout05} alt="資訊整合" />
          </div>
          <div className="homeAboutLinkCon" data-aos="fade-up"
            data-aos-duration="1500">
            <InternalLink
              to="/service/information-integration"
              title=" 資訊整合"
            ></InternalLink>
          </div>
          {/* 暫時先住解掉 <div className="homeAboutLinkCon" >
                    <Link className="homeAboutLink" to=''>Q-PARKING 官網</Link>
                </div> */}
        </div>
        <div
          className="HomeAboutImgContainer"
          data-aos="fade-down"
          data-aos-duration="1800"
        >
          <div className="HomeAboutImgBc"></div>
          <div className="HomeAboutImg"></div>
        </div>
      </div>
    </div>
  );
};
export default HomeAbout;
