import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux'
// import { headerActions } from '../../store/index'
const Breadcrumbs = (props: any) => {
    //先抓router
    const location = useLocation()
    const result = location.pathname.split('/')
    // const dispatch = useDispatch();
    const [resultNew, setResultNew] = useState()
    // const changeRouter = () => {
    //     const tData = result.forEach((elm, i) => {
    //         if (elm === 'about') {
    //             result[i] = '1212'
    //         }
    //     })
    //     // setResultNew(tData)
    // }
    useEffect(() => {
        let tData
        // changeRouter()
        if (result) {
            result.forEach((elm, i) => {
                if (elm === '') {
                    result[i] = '首頁'

                }
                if (elm === 'about') {
                    result[i] = '關於我們'

                }
                if (elm === 'history') {
                    result[i] = 'TIDC沿革'

                }
                tData = result

            })
        }
        // tdata = result.splice(result.indexOf('about'), 2, '關於我們')
        setResultNew(tData)
        // resultNew=tdata
    }, [])


    return (
      <></>
        // <div className="breadcrumbs">
        //     <ol itemScope itemType="https://schema.org/BreadcrumbList">
        //         <li itemProp="itemListElement" itemScope
        //             itemType="https://schema.org/ListItem">
        //             <Link itemProp="item" to="/">
        //                 <span itemProp="name">首頁</span></Link>
        //             <meta itemProp="position" content="1" />
        //         </li>
        //         <li itemProp="itemListElement" itemScope
        //             itemType="https://schema.org/ListItem">
        //             <Link itemProp="item" to="/about">
        //                 <span itemProp="name">關於我們</span></Link>
        //             <meta itemProp="position" content="2" />
        //         </li>

        //         <li itemProp="itemListElement" itemScope
        //             itemType="https://schema.org/ListItem">
        //             <Link itemProp="item" to="/about/history">
        //                 <span itemProp="name">TIDC 沿革</span></Link>
        //             <meta itemProp="position" content="2" />
        //         </li>
        //     </ol>
        // </div>


    );
};

export default Breadcrumbs;

