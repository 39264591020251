import NewsBtn from "../UI/NewsBtn";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchNewsIdContent } from "store/news/frontNews";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import React from "react";
import { fetchNewsNext, fetchNewsPrev } from "store/news/newsNextPrev";
import Loading from "components/mainLayout/Loading";
import { Helmet } from "react-helmet-async";
import {
    FacebookShareButton,
    FacebookIcon,
    LineShareButton,
    LineIcon
} from "react-share";
const NewsSection = () => {
    const dispatch = useDispatch<any>();
    const { newsid } = useParams();
    const newsContent = useSelector((state: RootState) => state.news.content);
    const prev = useSelector((state: RootState) => state.newsNextPrev.prev)
    const next = useSelector((state: RootState) => state.newsNextPrev.next)
    useEffect(() => {
        dispatch(fetchNewsIdContent({ id: newsid }));
        dispatch(fetchNewsPrev(newsid));
        dispatch(fetchNewsNext(newsid));
    }, [dispatch, newsid,]);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    }, [loading]);
    //抓loading
    // const loadingState = useSelector((state: RootState) => state.corp.loading)
    return (
        <>
            {
                loading ? <Loading></Loading> : null
            }
            <div className="newsSection">
                {newsContent &&
                    newsContent.map((val, index) => (
                        <React.Fragment key={index}>
                            <Helmet>
                                <title>最新消息-{val.title}｜TIDC台灣國際-智慧停車領航者</title>
                                <meta name="description" content={val.title} />
                                <meta property="og:title" content={val.title} />
                                <meta property="og:description" content={val.title} />
                                <meta property="og:image" content="https://my-article-image.com" />
                                <meta property="og:url" content={`http://web-tidc-group-dev-b85ef8dfd287a859.elb.ap-southeast-1.amazonaws.com/news/${val.id}`} />
                            </Helmet>
                            <div className="titleContainer">
                                <strong className="title">{val.title}</strong>
                                <div className="shareContainer">
                                    <span className='date'>{val.date}</span>
                                    <ul className="social">
                                        <FacebookShareButton
                                            title={val.title}
                                            url={`http://web-tidc-group-dev-b85ef8dfd287a859.elb.ap-southeast-1.amazonaws.com/news/${val.id}`}
                                        >
                                            <FacebookIcon round={true} size={45} />
                                        </FacebookShareButton>
                                        <LineShareButton
                                            title={val.title}
                                            url={`http://web-tidc-group-dev-b85ef8dfd287a859.elb.ap-southeast-1.amazonaws.com/news/${val.id}`}
                                        >
                                            <LineIcon round={true} size={45} />
                                        </LineShareButton>
                                    </ul>
                                </div>
                            </div>
                            <div className="newsInsiderContent">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: val.content
                                    }}
                                ></div>
                                <div className="clear-both"></div>
                            </div>
                            <div className="newsInsidernav">
                                <div className={` ${prev.length > 0 ? "newsInsidernavArrowleft" : "hidden"}`}>
                                    {prev && prev.map((val) => (<Link
                                        key={val.id}
                                        to={`/news/${val.id}`}
                                        className="newsInsidernavPrev"
                                        title={val.title.length > 15
                                            ? val.title.substring(0, 15) + "..."
                                            : val.title}
                                    >
                                        <span className="newsInsidernavlabel">上一篇</span>
                                    </Link>))
                                    }
                                </div>
                                <div className={` ${next.length > 0 ? "newsInsidernavArrowRight" : "hidden"}`}>
                                    {next && next.map((val) => (<Link
                                        key={val.id}
                                        to={`/news/${val.id}`}
                                        className="newsInsidernavNext"
                                        title={val.title.length > 15
                                            ? val.title.substring(0, 15) + "..."
                                            : val.title}
                                    >
                                        <span className="newsInsidernavlabel">下一篇</span>
                                    </Link>))
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                <NewsBtn className="m-auto" title="返回文章列表" href="/news"></NewsBtn>
            </div>
        </>
    );
};
export default NewsSection;
