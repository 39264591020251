
import { useEffect, useState } from 'react';
import InputField from '../UI/InputField'
import SelectField from '../UI/SelectField'
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextareaField from '../UI/TextareaField';
import SubmitButton from '../UI/SubmitButton';
import Loading from 'components/mainLayout/Loading';
import { postContact, postNoReplyEmail } from '../../utils/fetchAPI';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNoReplyToken } from 'store/contact/contact';
import { RootState } from 'store';


type ContactForm = {
    id: number;
    requirement_type: string;
    company_name: string;
    cellphone: string;
    contact_person: string;
    email: string;
    requirement_content: string;
};
const ContactForm = () => {
    const dispatch = useDispatch<any>();
    const [isloading, setIsLoading] = useState(false)
    const admin_token = useSelector((state: RootState) => state.contact.admin_token);
    // 採用react-hook-form
    const { register, handleSubmit, formState: { errors }, reset } = useForm<ContactForm>();

    //針對 teaxtarea 做字數偵測
    const [textAreaCount, settextAreaCount] = useState(0);

    useEffect(() => {
        //先拿到傳送no reply信件的token 
        dispatch(fetchNoReplyToken());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onSubmit = async (data: ContactForm) => {
        setIsLoading(true)
        try {
            //寄送表單的api
            const response = await postContact({
                ...data,
            });
            if (response.data.status_code === 200) {
                setIsLoading(false)
                //套件會觸發寄送成功的警示
                toast.success('寄送成功！', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: "light",
                });
                //由前端帶參數過去 觸發寄送no reply的mailapi
                (async () => {
                    //傳送no reply信件的api
                    dispatch(await postNoReplyEmail({
                        from_mail: "no-reply<service@greenparking.com.tw>",
                        to_mail: data.email,
                        mail_content_type: "html",
                        mail_content: `<!DOCTYPE html>
                                    <html>
                                    
                                    <head>
                                        <meta http-equiv='Content-Type' content='text/html; charset=UTF-8' />
                                        <xml>
                                            <o:OfficeDocumentSettings>
                                                <o:AllowPNG />
                                            </o:OfficeDocumentSettings>
                                        </xml>
                                    </head>
                                    <style>
                                        .ExternalClass {
                                            width: 100%;
                                        }
                                        @media screen and (max-width: 1024px) {
                                            table tr:nth-last-child(1) td img {
                                                width: 50% !important;
                                            }
                                        }
                                    
                                        @media screen and (max-width: 768px) {
                                            table tr:nth-last-child(1) td img {
                                                width: 60% !important;
                                            }
                                        }
                                    
                                        @media screen and (max-width: 640px) {
                                            table tr:nth-last-child(1) td img {
                                                width: 95% !important;
                                            }
                                        }
                                    </style>
                                    
                                    <body>
                                        <table style='border-collapse: collapse;width:100%;'>
                                            　<tr>
                                                　　<td style='font-family: sans-serif;font-weight: 600;letter-spacing: 2px;font-size: 20px;'>您好：</td>
                                                　</tr>
                                            　<tr style='height:4rem'>
                                                　　<td></td>
                                                　</tr>
                                            <tr>
                                                　　<td style='font-family: sans-serif;font-weight: 600;letter-spacing: 2px;font-size: 20px;'>
                                                    感謝您的來信諮詢，我們的專員在收到信件後將盡快為您提供服務。
                                                </td>
                                                　</tr>
                                            　<tr>
                                            <tr>
                                                　　<td style='font-family: sans-serif;font-weight: 600;letter-spacing: 2px;font-size: 20px;'>
                                                    若您需要直接聯繫相關人員，歡迎撥打我們的服務電話(04)2310-3319。</td>
                                                　</tr>
                                            　<tr style='height:3.125rem'>
                                                　　<td></td>
                                                　</tr>
                                            <tr>
                                                　　<td style='font-family: sans-serif;font-weight: 600;letter-spacing: 2px;font-size: 18px;'>
                                                    ※此信件為系統自動回覆，請勿直接回覆本信件</td>
                                                　</tr>
                                            　<tr style='height:10rem'>
                                                　　<td></td>
                                                　</tr>
                                            　<tr>
                                                　　<td><img id="noRrply"
                                                        src='https://tidc-offical-website.s3.ap-southeast-1.amazonaws.com/tidc/img/no-reply_mail/03.png'
                                                        alt='03' style='width:100%; max-width: 700px;'></td>
                                                　</tr>
                                    
                                        </table>
                                    
                                    </body>
                                    
                                    </html>`,
                        mail_title: "TIDC官網諮詢回覆-台灣國際開發事業有限公司",
                        admin_token: admin_token,
                    }));
                })();

                // 傳送表單備份至TIDC官方信箱
                (async () => {
                    dispatch(await postNoReplyEmail({
                        from_mail: "no-reply<service@greenparking.com.tw>",
                        to_mail: "service@greenparking.com.tw",
                        mail_content_type: "html",
                        mail_content: `<!DOCTYPE html>
                        <html>
                        <head>
                            <meta http-equiv='Content-Type' content='text/html; charset=UTF-8' />
                            <xml>
                                <o:OfficeDocumentSettings>
                                    <o:AllowPNG />
                                </o:OfficeDocumentSettings>
                            </xml>
                        </head>
                        <style>
                            .ExternalClass {
                                width: 100%;
                            }
                        
                            @media screen and (max-width: 1024px) {
                                table tr:nth-last-child(1) td img {
                                    width: 50% !important;
                                }
                            }
                        
                            @media screen and (max-width: 768px) {
                                table tr:nth-last-child(1) td img {
                                    width: 60% !important;
                                }
                            }
                        
                            @media screen and (max-width: 640px) {
                                table tr:nth-last-child(1) td img {
                                    width: 95% !important;
                                }
                            }
                        </style>
                        <body>
                            <table style='border-collapse: collapse;width:100%;'>
                                <tr style='height:1rem'>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td style='font-family: sans-serif;font-weight: 600;letter-spacing: 2px;font-size: 16px;'>
                                        TIDC官網《聯絡我們》諮詢通知
                                    </td>
                                </tr>
                                <tr style='height:3rem'>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td style='font-family: sans-serif;font-weight: 600;letter-spacing: 2px;font-size: 16px;'>
                                        ●&ensp;公司名稱：${data.company_name}
                                    </td>
                                </tr>
                                <tr style='height:1rem'>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td style='font-family: sans-serif;font-weight: 600;letter-spacing: 2px;font-size: 16px;'>
                                        ●&ensp;需求類型：${data.requirement_type}
                                    </td>
                                </tr>
                                <tr style='height:1rem'>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td style='font-family: sans-serif;font-weight: 600;letter-spacing: 2px;font-size: 16px;'>
                                        ●&ensp;聯絡人：${data.contact_person}
                                    </td>
                                </tr>
                                <tr style='height:1rem'>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td style='font-family: sans-serif;font-weight: 600;letter-spacing: 2px;font-size: 16px;'>
                                        ●&ensp;手機電話：${data.cellphone}
                                    </td>
                                </tr>
                                <tr style='height:1rem'>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td style='font-family: sans-serif;font-weight: 600;letter-spacing: 2px;font-size: 16px;'>
                                        ●&ensp;電子信箱：${data.email}
                                    </td>
                                </tr>
                                <tr style='height:1rem'>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td style='font-family: sans-serif;font-weight: 600;letter-spacing: 2px;font-size: 16px;'>
                                        ●&ensp;諮詢需求：${data.requirement_content}
                                    </td>
                                </tr>
                                <tr style='height:4rem'>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td style='font-family: sans-serif;font-weight: 600;letter-spacing: 2px;font-size: 16px;'>
                                        ※ 系統自動回傳，請勿直接回覆本信件
                                    </td>
                                </tr>
                                <tr style='height:2rem'>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <img id="noRrply"
                                        src='https://tidc-offical-website.s3.ap-southeast-1.amazonaws.com/tidc/img/no-reply_mail/03.png'
                                        alt='03' style='width:100%; max-width: 700px;'>
                                    </td>
                                </tr>
                            </table>
                        </body>
                        </html>`,
                        mail_title: "TIDC官網《聯絡我們》諮詢通知",
                        admin_token: admin_token,
                    }));
                })();
            }

        } catch (error) {
            console.log(error)
            setIsLoading(false)
            if (error) {
                toast.error('寄送失敗！', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: "dark",
                });
            }
        }
        finally {
            // 清空表單
            reset()
        }
    };
    return (<>
        {
            isloading ? <Loading></Loading> : null
        }
        {/* 下拉選單 */}
        <form className='form' onSubmit={handleSubmit(onSubmit)} >
            {/* 下拉選單 */}
            <div className="mb-8">
                <SelectField
                    {...register("requirement_type", { required: true, maxLength: 30 })}
                    label='需求類型 '
                    placeholder='場域規劃、顧問諮詢、資訊整合..等等'
                    className='selectField'
                    labelClassName='formLabel break-keep shrink-0'
                    error={errors.requirement_type}
                />
                {errors?.requirement_type?.type === "required" && <p className='error'>請填寫這個欄位</p>}

            </div>
            <div className="lg:flex lg:gap-9 justify-between mb-8">
                <div className='lg:w-3/5 mb-8 lg:mb-0'>
                    {/* 公司欄位 */}
                    <InputField
                        {...register("company_name", {
                            required: true,
                            maxLength: 30,
                            setValueAs: value => value.trim(),
                        })}
                        label='公司名稱 '
                        placeholder='台灣國際開發事業有限公司'
                        labelClassName='formLabel break-keep shrink-0'
                        maxlength="30"
                        error={errors.company_name}
                    />
                    {errors?.company_name?.type === "required" && <p className='error'>請填寫這個欄位</p>}
                    {errors?.company_name?.type === "maxLength" && (
                        <p className='error'> 字數不可超過30字</p>
                    )}
                </div>
                <div className="lg:w-2/5 mb-8 lg:mb-0">
                    {/* 手機欄位 */}
                    <InputField
                        {...register("cellphone", {
                            required: true,
                            pattern: /^(09\d{8})$/,
                            maxLength: 10,
                            setValueAs: value => value.trim(),
                        })}
                        label='手機電話'
                        placeholder='0912345678'
                        maxlength="10"
                        labelClassName='formLabel break-keep shrink-0'
                        error={errors}
                    />
                    {errors?.cellphone?.type === "required" && <p className='error'>請填寫這個欄位</p>}
                    {errors?.cellphone?.type === "maxLength" && (
                        <p className='error'>字數不可超過10字</p>
                    )}
                    {errors?.cellphone?.type === "pattern" && (
                        <p className='error'>輸入格式不正確</p>
                    )}
                </div>

            </div>
            <div className="lg:flex gap-9 justify-between ">
                <div className='lg:w-1/3 mb-8'>
                    {/* 聯絡人欄位 */}
                    <InputField
                        {...register("contact_person", {
                            required: true,
                            maxLength: 30,
                            setValueAs: value => value.trim(),
                        })}
                        label='聯絡人'
                        placeholder='請輸入姓名'
                        maxlength="30"
                        labelClassName='formLabel break-keep shrink-0'
                        error={errors}
                    />
                    {errors?.contact_person?.type === "required" && <p className='error'>請填寫這個欄位</p>}
                    {errors?.contact_person?.type === "maxLength" && (
                        <p className='error'>字數不可超過30字</p>
                    )}
                </div>
                <div className="lg:w-2/3 mb-8 lg:mb-0">
                    {/* email欄位 */}
                    <InputField
                        {...register("email", {
                            required: true,
                            pattern: /^\w+([.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            maxLength: 30,
                            setValueAs: value => value.trim(),
                        })}
                        label='電子郵件'
                        placeholder='service@tidc.com.tw'
                        className=''
                        maxlength="30"
                        labelClassName='formLabel break-keep shrink-0'
                        error={errors}
                    />
                    {errors?.email?.type === "required" && <p className='error'>請填寫這個欄位</p>}
                    {errors?.email?.type === "maxLength" && (
                        <p className='error'>字數不可超過30字</p>
                    )}
                    {errors?.email?.type === "pattern" && (
                        <p className='error'>輸入格式不正確</p>
                    )}
                </div>

            </div>
            <div className='mb-8'>
                {/* 詢問欄位 */}
                <TextareaField
                    {...register("requirement_content", {
                        required: true,
                        maxLength: 350,
                        setValueAs: value => value.trim(),
                    })}
                    label='諮詢需求'
                    placeholder='請簡述您的需求'
                    className='items-center justify-center pt-6 '
                    labelClassName='formLabel  break-keep shrink-0'
                    maxlength="350"
                    error={errors.requirement_content}
                    onChange={e => settextAreaCount(e.target.value.length)}
                    textCount={textAreaCount}
                />
                {errors?.requirement_content?.type === "required" && <p className='error'>請填寫這個欄位</p>}
                {errors?.requirement_content?.type === "maxLength" && (
                    <p className='error'>字數不可超過350字</p>
                )}
            </div>
            <ToastContainer
            />
            <div className='flex justify-center gap-6 items-center lg:justify-start'>
                <div onClick={() => {
                    reset({
                        requirement_type: "",
                        company_name: '',
                        cellphone: '',
                        contact_person: '',
                        email: '',
                        requirement_content: '',
                    })
                }} className='submitBtn bg-lightOrange'>取消送出</div>
                <SubmitButton type="submit" className='submitBtn bg-lightBlue' title='送出資料'></SubmitButton>
            </div>
        </form>
    </>
    )
}
export default ContactForm;
