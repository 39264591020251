import hire01 from '../../frontImage/hire01.png'

const HireMainVisual = () => {
    return (<>
        <div className="flex justify-between items-center gap-8 mb-40 visualContainer careervisual"  data-aos="fade-down"
            data-aos-duration="1500">
            <div className="visualContent">
                <div className='visualTitleContainer'>
                    <h3 className="enTitle">Careers</h3>
                    <h2 className="visualTitle">人力資源</h2>
                </div>
                <p >
                創新與改變，從台灣國際開始﹗<br/>藉著轉型的契機，發展開創及跨域的多元能力，期許未來在科技領域的道路上，都有我們的一份努力﹗最值得珍惜的工作，是跟一群有理想的夥伴共同奮鬥。<br/>我們感謝每位加入台灣國際的夥伴﹗我們彼此關懷扶持、互助成長，韌性是我們最大的力量。
                </p>
            </div>
            <div className="visualImg 2xl:w-1/2  sm:w-4/5  w-full">
                <img src={hire01} alt="人力資源" />
            </div>
        </div>
    </>)

}
export default HireMainVisual