const AwardSection = ({ awardContent, className }: { awardContent: any, className?: string }) => {
  return (
    <>
      <div className={`awardSection ${className}`}>
        <div className="container">
          {awardContent &&
            awardContent.map(
              (val: {
                id: number;
                year: number;
                content: string;
                image_src: string | undefined;
                image_alt: string | undefined;
              }) => (
                <div className="card" key={val.id}>
                  <div
                    className="contentContainer"
                    data-aos="fade-down"
                    data-aos-duration="1500"
                  >
                    <div className="content">
                      <div className="year">{val.year}</div>
                      <div className="detail">
                        <p>{val.content}</p>
                      </div>
                    </div>
                    <div className="imgContainer">
                      <img src={val.image_src} alt={val.image_alt} />
                    </div>
                  </div>
                  <span className="lineDeca"></span>
                </div>
              )
            )}
        </div>
      </div>
    </>
  );
};
export default AwardSection;
