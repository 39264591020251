import AsideNav from '../../components/mainLayout/AsideNav'
import { Link } from 'react-router-dom'
import HireMainSection from 'components/hire/HireMainSection'
import HireMainVisual from 'components/hire/HireMainVisual'
import { Helmet } from 'react-helmet-async'
import ContactLink from 'components/UI/ContactLink'
const HireMain = () => {
    return (<>
        <Helmet>
            <title>人力資源｜TIDC台灣國際-智慧停車領航者</title>
        </Helmet>
        <AsideNav showIndex={3}></AsideNav>
        <div className='selfContainer'>
            <span className="breadcrumbs">
                <ol className="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/">
                            <span itemProp="name">首頁</span></Link>
                        <meta itemProp="position" content="1" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <span itemProp="name">人力資源</span>
                        <meta itemProp="position" content="2" />
                    </li>
                </ol>
            </span>
            <HireMainVisual ></HireMainVisual>
            <HireMainSection></HireMainSection>
            <ContactLink className='downContactContainer mt-40'></ContactLink>

        </div>
    </>
    )

}
export default HireMain