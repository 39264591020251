import Home from './page/Home';
//about
import About from './page/about/AboutMain';
import AboutBrand from 'page/about/AboutBrand';
import AboutCopo from 'page/about/AboutCopo';
import AboutEsq from 'page/about/AboutEsq';
import AboutHistory from 'page/about/AboutHistory';
import AboutTeam from 'page/about/AboutTeam';
// sercive
import Service from 'page/service/ServiceMain';
import ServiceInter from 'page/service/ServiceInter';
import ServiceSpace from 'page/service/ServiceSpace';
//hire
import Hire from './page/hire/HireMain'
import HirePolicy from './page/hire/HirePolicy'
// contact
import Contact from './page/contact/ContactMain'
//award
import AwardMain from './page/awrad/AwardMain'
import Award01 from './page/awrad/Award01'
import Award02 from './page/awrad/Award02'
import Award03 from './page/awrad/Award03'
//news
import NewsMain from './page/news/NewsMain';
import NewsIndex from './page/news/NewsIndex';
// loading
import Loading from '../src/components/mainLayout/Loading';
//policy
import PolicyMain from './page/PolicyMain';
//404
import PageNotFound from 'page/PageNotFound';
import Award04 from 'page/awrad/Award04';
import NewsCompany from 'page/news/NewsCompany';
import NewsMedia from 'page/news/NewsMedia';
import Redirect from 'components/UI/Redirect';
// breadcrumbName 之所以是註解是希望未來能動態切換麵包屑
const routes = [
  {
    path: '/',
    component: < Home />,
    exact: true,
    // breadcrumbName: '首頁'
  },
  //about
  {
    path: '/about',
    component: < About />,
    // breadcrumbName: '關於我們',
  },
  {
    path: '/about/history',
    component: <  AboutHistory />,
    // breadcrumbName: 'TIDC 沿革'
  },
  {
    path: '/about/brand',
    component: < AboutBrand />,
    // breadcrumbName: 'TIDC 品牌'
  },
  {
    path: '/about/team',
    component: < AboutTeam />,
    // breadcrumbName: 'TIDC TEAM'
  },
  {
    path: '/about/cop',
    component: < AboutCopo />,
    // breadcrumbName: 'TIDC 合作夥伴'
  },
  {
    path: '/about/esg',
    component: < AboutEsq />,
    // breadcrumbName: '永續ESG'
  },
  //service
  {
    path: '/service',
    component: <Service />,
    // breadcrumbName: '服務方案',
  },
  {
    path: '/service/information-integration',
    component: <ServiceInter />,
    // breadcrumbName: '資訊整合'
  },
  {
    path: '/service/smart-parking-solution',
    component: <ServiceSpace />,
    // breadcrumbName: '智慧停車規劃'
  },

  //award
  {
    path: '/award',
    component: <AwardMain />,
    // breadcrumbName: '各界肯定',

  },
  {
    path: '/award/gta',
    component: <Award01 />,
    // breadcrumbName: '金擘獎'
  },
  {
    path: '/award/fiabci',
    component: <Award02 />,
    // breadcrumbName: '國家卓越建設獎'
  },
  {
    path: '/award/cloudaward',
    component: <Award03 />,
    breadcrumbName: '雲端物聯網創新獎'
  },
  {
    path: '/award/servicequality',
    component: <Award04 />,
    breadcrumbName: '服務品質獎項'
  },
  //hire
  {
    path: '/careers',
    component: <Hire />,
    breadcrumbName: '人力資源',
  },
  {
    path: '/careers/employee-benefits',
    component: <HirePolicy />,
    breadcrumbName: '福利及政策'
  },
  //員工專區暫時先移除
  // {
  //   path: '/careers/personnel02',
  //   component: <Hire />,
  //   breadcrumbName: '員工專區'
  // },
  //news
  {
    path: '/news',
    component: <NewsMain />,

  },
  {
    path: '/news/company',
    component: <NewsCompany />,
    // breadcrumbName: '企業快訊',
  },
  {
    path: '/news/media',
    component: <NewsMedia />,
    // breadcrumbName: '企業快訊',
  },
  {
    path: '/news/:newsid',
    component: <NewsIndex />,
    // breadcrumbName: '企業快訊',
  },
  // contact
  {
    path: '/contact',
    component: <Contact />,
    breadcrumbName: '聯絡我們',
  },
  // policy
  {
    path: '/policy',
    component: <PolicyMain />,

  },
  // loading
  {
    path: '/loading',
    component: <Loading />,

  },
  // 404 要寫在最後！！！！！
  {
    path: '*',
    component: <PageNotFound />,
  },
  // 嘉義繳費外站導引連結，一連結到頁面及要導引連結
  {
    path: '/chiayi',
    component: <Redirect city="chiayi" />,
  },
  {
    path: '/chiayi/query',
    component: <Redirect city="chiayi" />,
  },
  {
    path: '/chiayi/query/get',
    component: <Redirect city="chiayi" />,
  },
  // 金門繳費外站導引連結
  {
    path: '/kinmen',
    component: <Redirect city="kinmen" />,
  },
  {
    path: '/kinmen/query',
    component: <Redirect city="kinmen" />,
  },
  {
    path: '/kinmen/query/get',
    component: <Redirect city="kinmen" />,
  }, 
];

export default routes;