import { getTimeLineMain, getTimeLineContent } from '../../utils/fetchAPI'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { combinTable } from '../../utils/dataHandling'

interface timelineState {
    index: any[]
    content: string[]
    total: string[]
    loading: boolean
    error: string
}
const initialState = {
    index: [],
    content: [],
    total: [],
    loading: false,
    error: "",
} as timelineState

export const fetchtimelineMain = createAsyncThunk(
    'api/timeline', async () => {
        const response = await getTimeLineMain()
        return response.data;
    })
export const fetchtimeContent = createAsyncThunk(
    'api/timelineContent', async () => {
        const response = await getTimeLineContent()
        return response.data;
    })

export const timelineSlice = createSlice({
    name: 'timeline',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        composition: (state, action) => {
            combinTable(state.index,state.content)
            state.total = action.payload
        },

    },
    extraReducers: (builder: any) => {
        builder.addCase(fetchtimelineMain.pending, (state: timelineState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchtimelineMain.fulfilled, (state: timelineState, action: { payload: any; }) => {
            state.loading = false
            state.index = action.payload.datas;
            return state;
        })

        builder.addCase(fetchtimelineMain.rejected, (state: timelineState, action: { error: { message: any; }; }) => {
            state.loading = false
            return state
        })
        builder.addCase(fetchtimeContent.pending, (state: timelineState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchtimeContent.fulfilled, (state: timelineState, action: { payload: any; }) => {
            state.loading = false
            state.content = action.payload.datas;
            return state;
        })

        builder.addCase(fetchtimeContent.rejected, (state: timelineState, action: { error: { message: any; }; }) => {
            state.loading = false
            state.error = action.error.message
            return state
        })
    }
});

export const timelineActions = timelineSlice.actions;
export default timelineSlice.reducer