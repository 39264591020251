import { useEffect, useState } from 'react'
import aboutBrand05 from '../../frontImage/aboutBrand05.png'
const AboutBrandVisual = () => {
    const [textIndex, SetTextIndex] = useState(0)
    const [text, setText] = useState("")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fullText, setFullText] = useState(
        "三大品牌的循環經營使命".split('')
    )
    useEffect(() => {
        if (textIndex < fullText.length) {
            setTimeout(() => {
                setText(text + fullText[textIndex])
                SetTextIndex(textIndex + 1)
            }, 200)
        }
    }, [fullText, text, textIndex])
    return (<div className="aboutMainVisual">
        <div className="aboutMainVisualContainer">
            <div className="aboutMainVisualContent"  >
                <span className="aboutMainVisualTitle">{text}</span>
                <p data-aos="fade-down"
                    data-aos-duration="1500">
                    台灣國際經營18年來，提供政府及企業在路邊或路外停車場域的規畫經營與建議，從服務缺口發展適切的方案、以使用者為中心規劃人本友善的設施及服務，創造便利且使用體驗滿分的場域及服務。<br />且洞悉智慧時代的來臨，開始建構停車大數據的資訊整合平台，開發人性化且易操作的管理系統，將數據回饋至交通治理、民眾應用端，協助單位精準掌握交通數據，同時滿足民眾了解即時停車資訊的期望與需求。
                </p>
            </div>
            <div className="aboutMainVisualImg" data-aos="zoom-out-down"
                data-aos-duration="1800">
                <img src={aboutBrand05} alt="關於我們品牌意象循環圖" />
            </div>
        </div>
    </div>)

}
export default AboutBrandVisual