import Counter from 'components/homeMain/Counter'
import { useEffect, useState } from 'react'
import Carousel from '../components/homeMain/Carousel'
import HomeAbout from '../components/homeMain/HomeAbout'
import HomeNews from '../components/homeMain/HomeNews'
import ScrollAnime from '../components/UI/ScrollAnime'
//store
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../store/index";
//需要抓資料的要變更後面的路徑
import { fetchData } from "../store/home/carousel";
import { fetchCounter } from "../store/home/counter";
import { Helmet } from 'react-helmet-async'
import { fetchHomeNewsLatest, fetchHomeNewsPin } from 'store/home/homenews'
import Loading from 'components/mainLayout/Loading'

const Home = () => {
    const dispatch = useDispatch<any>();
    // 需要拿到的資料
    const carousel = useSelector((state: RootState) => state.carousel.carouselList);
    const counter = useSelector((state: RootState) => state.counter.counterlList)
    const latest = useSelector((state: RootState) => state.homenews.latest);
    const pin = useSelector((state: RootState) => state.homenews.pin)
    // //抓loading
    // const loadingState = useSelector((state: RootState) => state.homenews.loading)
    //此為載入這個頁面前先執行的，由他去觸發api
    useEffect(() => {
        dispatch(fetchData());
        dispatch(fetchCounter());
        dispatch(fetchHomeNewsLatest());
        dispatch(fetchHomeNewsPin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    }, [loading]);
    return (<>
        {
            loading ? <Loading></Loading> : null
        }
        <Helmet>
            <title>TIDC台灣國際｜智慧停車領航者</title>
        </Helmet>
        <Carousel carouselList={carousel}></Carousel>
        <Counter counterList={counter} ></Counter>
        <ScrollAnime></ScrollAnime>
        <HomeAbout></HomeAbout>
        <HomeNews latest={latest} pin={pin}></HomeNews>
    </>)
}
export default Home