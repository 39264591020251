import { useEffect } from "react";
// import { redirect } from "react-router-dom";

const Redirect = (props: { city: string }) => {
    const { city } = props;
    
    useEffect(() => {
        window.location.replace(`https://${city}.greenparking.com.tw/${city}/query`);
        // redirect(("https://chiayi.greenparking.com.tw/chiayi/query"))
    }, [city])
    
    return (
        <div></div>
    );
};
export default Redirect;
