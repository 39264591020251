import aboutBrand01 from '../../frontImage/aboutBrand01.png'
import aboutBrand02 from '../../frontImage/aboutBrand02.png'
import aboutBrand03 from '../../frontImage/aboutBrand03.png'
import aboutBrand04 from '../../frontImage/aboutBrand04.png'
import ContactLink from '../UI/ContactLink'
// gp link 的組件
// import LeftLinkBtn from '../UI/LeftLinkBtn'

const AboutBrandSection = () => {
    const aboutBrandList = [{
        titleImg: aboutBrand03,
        titleImgAlt: ' GP logo',
        content: "Qparking利用TIDC所開發的資訊整合平台，串聯消費者、停車場業者、商圈三方，解決消費者搜尋停車位的不便利，為各式停車場導入新進人流同時，成為串聯商家與停車業者的平台，致力於打造互利共生的新經濟平台。 "
        , src: aboutBrand04, alt: 'GP主視覺',
        link: "https://michalsnik.github.io/aos/", linkTitle: "Q-PARKING 官網",
    }, {
        titleImg: aboutBrand01,
        titleImgAlt: 'QPK logo',
        content: "GreenPark將TIDC經營理念實踐於停車場域，致力於協助全台非專業經營的停車場域，擺脫傳統停車場的不良印象，並搭配便利且人性化的管理模式，整合集團資源使場域經營管理邁向專業化、科技化。"
        , src: aboutBrand02, alt: 'QPK主視覺', link: "", linkTitle: "GREEN PARK 官網",
    },]
    return (
        <>
            {aboutBrandList.map((val, index: number) => {
                return (
                    <div key={index} className="aboutBrandMainVisual"  data-aos="fade-down"
                    data-aos-duration="1000">
                        <div className="aboutMainVisualContainer">
                            <div className="aboutMainVisualContent" data-aos="fade-down"
                                data-aos-duration="1500">
                                <div className='logo' >
                                    <img src={val.titleImg} alt={val.titleImgAlt} />
                                </div>
                                <p >
                                    {val.content}
                                </p>
                            </div>
                            <div className="aboutMainVisualImg" data-aos="zoom-in-out"
                                data-aos-duration="1800">
                                <img src={val.src} alt={val.alt} />
                            </div>
                        </div>

                        {/* {
                            暫時先把gp link移除
                            val.link === "" ? <div className="linkContainer">
                                <LeftLinkBtn href={val.link} title={val.linkTitle} className="mt-32" data-aos="fade-down"
                                    data-aos-duration="2000" ></LeftLinkBtn>
                            </div> : null
                        } */}
                    </div>
                )
            })}
            <ContactLink className='downContactContainer'></ContactLink></>

    )
}
export default AboutBrandSection