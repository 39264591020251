import AsideNav from '../../components/mainLayout/AsideNav'
import AboutTeamSection from '../../components/aboutMain/AboutTeamSection'
import { Link } from 'react-router-dom'
import Breadcrumbs from "../../components/UI/Breadcrum";
import { useEffect, useState } from 'react';
import { fetchteamContent, fetchteamMain } from 'store/about/team';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Helmet } from 'react-helmet-async';
import Loading from 'components/mainLayout/Loading';


const AboutTeam = () => {
    const dispatch = useDispatch<any>();
    // 需要拿到的資料
    const team = useSelector((state: RootState) => state.team.index);
    const teamContent = useSelector((state: RootState) => state.team.content)
    useEffect(() => {
        dispatch(fetchteamMain());
        dispatch(fetchteamContent());
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // //抓loading
    // const loadingState = useSelector((state: RootState) => state.team.loading)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }, [loading]);
    return <>
        {
            loading ? <Loading></Loading> : null
        }
        <Helmet>
            <title>關於我們-合作夥伴｜TIDC台灣國際-智慧停車領航者</title>
        </Helmet>
        <AsideNav showIndex={0}></AsideNav>
        <Breadcrumbs></Breadcrumbs>

        <div className='selfContainer'>
            <div className="breadcrumbs">
                <ol itemScope itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/">
                            <span itemProp="name">首頁</span></Link>
                        <meta itemProp="position" content="1" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/about">
                            <span itemProp="name">關於我們</span></Link>
                        <meta itemProp="position" content="2" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <span itemProp="name">TIDC TEAM</span>
                        <meta itemProp="position" content="3" />
                    </li>
                </ol>
            </div>
            <AboutTeamSection team={team} teamContent={teamContent} ></AboutTeamSection>
        </div>

    </>;

}
export default AboutTeam

