import AsideNav from '../../components/mainLayout/AsideNav'
import { Link } from 'react-router-dom'
import Breadcrumbs from "../../components/UI/Breadcrum";
import AwardSection from 'components/awardMain/AwardSection';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { fetchawardContent } from 'store/award/awardcontent';
import Loading from 'components/mainLayout/Loading';
import ContactLink from 'components/UI/ContactLink';


const Award03 = () => {
    const dispatch = useDispatch<any>();
    const awardContent = useSelector((state: RootState) => state.award.content)
    // //抓loading
    // const loadingState = useSelector((state: RootState) => state.award.loading)
    useEffect(() => {
        dispatch(fetchawardContent({ awards_id: 3, desc: "year" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }, [loading]);
    return <>
        {
            loading ? <Loading></Loading> : null
        }
        <Helmet>
            <title>各界肯定-雲端物聯網創新獎｜TIDC台灣國際-智慧停車領航者</title>
        </Helmet>
        <AsideNav showIndex={2}></AsideNav>
        <Breadcrumbs></Breadcrumbs>

        <div className='selfContainer'>
            <div className="breadcrumbs">
                <ol itemScope itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/">
                            <span itemProp="name">首頁</span></Link>
                        <meta itemProp="position" content="1" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/award">
                            <span itemProp="name">各界肯定</span></Link>
                        <meta itemProp="position" content="2" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <span itemProp="name">雲端創新獎</span>
                        <meta itemProp="position" content="3" />
                    </li>
                </ol>
            </div>
            <AwardSection className="lg:h-screen " awardContent={awardContent}></AwardSection>
            <ContactLink className='downContactContainer mt-40'></ContactLink>

        </div>

    </>;

}
export default Award03