
import Vector from '../../frontImage/vector.svg'
const ScrollAnime = () => {
    return (
        <div className="arrowDownContainer ">
            <div className="arrowDown " >
                <span className="text">SCROLL</span>
                <img className='arrowDownArrow' src={Vector} alt="指引" />
            </div>
        </div>
    );
};
export default ScrollAnime;
