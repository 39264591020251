const Loading = () => {
  return (
    <> <div className="loading" >
      <div className="imgContainer">
        <div className=" bg-slate-500"></div>
      </div>
    </div>
    </>

  );
};
export default Loading;
