import AsideNav from '../../components/mainLayout/AsideNav'
import TimeLine from '../../components/aboutMain/TimeLine'
import { Link } from 'react-router-dom'
import Breadcrumbs from "../../components/UI/Breadcrum";
import { useEffect, useState } from 'react';
//store
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../store/index";
//需要抓資料的要變更後面的路徑
import { fetchtimelineMain, fetchtimeContent } from "../../store/about/timeline";
import { Helmet } from 'react-helmet-async';
import Loading from 'components/mainLayout/Loading';
const AboutHistory = () => {
  const dispatch = useDispatch<any>();
  // 需要拿到的資料
  const timeline = useSelector((state: RootState) => state.timeline.index);
  const timelineContent = useSelector((state: RootState) => state.timeline.content)
  const [latesid,setLatesid]=useState<number| string |undefined>(-1)
  useEffect(() => {
    dispatch(fetchtimelineMain());
    dispatch(fetchtimeContent());
    setLatesid((timeline)[0])
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // //抓loading
  // const loadingState = useSelector((state: RootState) => state.timeline.loading)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
      if (loading) {
          setTimeout(() => {
              setLoading(false);
          }, 2000);
      }
  }, [loading]);


  return <>
    {
      loading ? <Loading></Loading> : null
    }
    <Helmet>
      <title>關於我們-沿革｜TIDC台灣國際-智慧停車領航者</title>
    </Helmet>
    <AsideNav showIndex={0}></AsideNav>
    <Breadcrumbs></Breadcrumbs>
    <div className='selfContainer'>
      <div className="breadcrumbs">
        <ol itemScope itemType="https://schema.org/BreadcrumbList">
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <Link itemProp="item" to="/">
              <span itemProp="name">首頁</span></Link>
            <meta itemProp="position" content="1" />
          </li>
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <Link itemProp="item" to="/about">
              <span itemProp="name">關於我們</span></Link>
            <meta itemProp="position" content="2" />
          </li>

          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <span itemProp="name">TIDC 沿革</span>
            <meta itemProp="position" content="2" />
          </li>
        </ol>
      </div>
      <TimeLine timeline={timeline} timelineContent={timelineContent} latesId={timeline[0]}></TimeLine>

    </div>
  </>;
};
export default AboutHistory;

