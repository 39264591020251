import { getAwardContent } from '../../utils/fetchAPI'
import { createSlice, createAsyncThunk} from '@reduxjs/toolkit'

interface awardState {
    content: string[]
    loading: boolean
    error: string
}
const initialState = {
    content: [],
    loading: false,
    error: "",
} as awardState
export const fetchawardContent = createAsyncThunk(
    'api/awardContent', async (val:object) => {
        const response = await getAwardContent(val)
        return response.data;
    })

export const awardSlice = createSlice({
    name: 'award',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },

    },
    extraReducers: (builder: any) => {
        builder.addCase(fetchawardContent.pending, (state: awardState) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchawardContent.fulfilled, (state: awardState, action: { payload: any; }) => {
            state.loading = false
            state.content = action.payload.datas;
            return state;
        })

        builder.addCase(fetchawardContent.rejected, (state: awardState, action: { error: { message: any; }; }) => {
            state.loading = false
            return state
        })
    }
});

export const teamActions = awardSlice.actions;
export default awardSlice.reducer