import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import AsideNav from '../../components/mainLayout/AsideNav'
import ServiceSpaceMain from '../../components/serviceMain/ServiceSpaceMain'
import ServiceSpaceSection from '../../components/serviceMain/ServiceSpaceSection'
import { RootState } from 'store'
import { fetcIotMain, fetchIotContent } from "../../store/service/iot";
import Loading from 'components/mainLayout/Loading'

const ServiceSpace = () => {
    const dispatch = useDispatch<any>();
    const iot = useSelector((state: RootState) => state.iot.index);
    const iotContent = useSelector((state: RootState) => state.iot.content)
    //抓loading
    // const loadingState = useSelector((state: RootState) => state.iot.loading)
    useEffect(() => {
        dispatch(fetcIotMain());
        dispatch(fetchIotContent());
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }, [loading]);
    return (<>
        {
            loading ? <Loading></Loading> : null
        }
        <Helmet>
            <title>服務方案-智慧停車規劃｜TIDC台灣國際-智慧停車領航者</title>
        </Helmet>
        <AsideNav showIndex={1}></AsideNav>
        <div className='selfContainer'>
            <span className="breadcrumbs">
                <ol className="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/">
                            <span itemProp="name">首頁</span></Link>
                        <meta itemProp="position" content="1" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <Link itemProp="item" to="/service"><span itemProp="name">服務方案</span></Link>
                        <meta itemProp="position" content="2" />
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem">
                        <span itemProp="name">智慧停車規劃</span>
                        <meta itemProp="position" content="3" />
                    </li>
                </ol>
            </span>
            <ServiceSpaceMain></ServiceSpaceMain>
            <ServiceSpaceSection iot={iot} iotContent={iotContent} ></ServiceSpaceSection>
        </div>

    </>
    )

}
export default ServiceSpace