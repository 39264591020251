import AsideNav from '../../components/mainLayout/AsideNav'
import AboutEsqSection from '../../components/aboutMain/AboutEsqSection'
import { Link } from 'react-router-dom'
import Breadcrumbs from "../../components/UI/Breadcrum";
import { Helmet } from 'react-helmet-async';
import AboutEsgVisual from '../../components/aboutMain/AboutEsgVisual';

const AboutEsq = () => {
  return <>
    <Helmet>
      <title>關於我們-永續ESG｜TIDC台灣國際-智慧停車領航者</title>
    </Helmet>
    <AsideNav showIndex={0}></AsideNav>
    <Breadcrumbs></Breadcrumbs>
    <div className='selfContainer'>
      <div className="breadcrumbs">
        <ol itemScope itemType="https://schema.org/BreadcrumbList">
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <Link itemProp="item" to="/">
              <span itemProp="name">首頁</span></Link>
            <meta itemProp="position" content="1" />
          </li>
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <Link itemProp="item" to="/about">
              <span itemProp="name">關於我們</span></Link>
            <meta itemProp="position" content="1" />
          </li>
          <li itemProp="itemListElement" itemScope
            itemType="https://schema.org/ListItem">
            <span itemProp="name">永續ESG</span>
            <meta itemProp="position" content="2" />
          </li>
        </ol>
      </div>
      <AboutEsgVisual ></AboutEsgVisual>
      <AboutEsqSection></AboutEsqSection>
    </div>
  </>;
};
export default AboutEsq;
