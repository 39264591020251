import serviceInter01 from '../../frontImage/serviceInter01.png'
const ServiceInterMain = () => {
    return (<>
        <div className="serviceInter " data-aos="fade-down"
            data-aos-duration="1500">
            <div className="flex justify-between items-center gap-8 mb-40   visualContainer">
                <div className="visualContent" >
                    <p >
                        台灣國際長期深耕智慧停車領域，藉由多元的物聯物件（路外停管設備、路邊地磁、智慧立柱）以及我們的巡場管理師團隊。<br /><br />於城市的各個角落匯集停車相關數據，透過IoT以及即時開單資訊系統將數據進行收容，並將數據淬煉為可以運用、分析的資訊。<br /><br />合適地揭露資訊與大數據賦能，提供優質的資訊服務，回應這世代在智慧停車的需求。
                    </p>
                </div>
                <div className="visualImg 2xl:w-1/2  sm:w-4/5  w-full" data-aos="zoom-out-down"
                    data-aos-duration="1500">
                    <img src={serviceInter01} alt='資訊整合' />
                </div>
            </div>
        </div>
    </>
    )

}
export default ServiceInterMain