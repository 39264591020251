import { forwardRef } from 'react'

interface Props {
    label?: string,
    name: string,
    placeholder?: string,
    className?: string,
    inputClassName?: string,
    labelClassName?: string,
    required?: boolean,
    error?: object,
    noLabel?: boolean,
    disabled?: boolean,
    defaultValue?: string,
    value?: string,
    maxlength?: any,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    onBlur: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    textCount:number,
}
const TextareaField = forwardRef<HTMLTextAreaElement, Props>(
    ({
        name,
        label,
        onChange,
        onBlur,
        className,
        inputClassName,
        labelClassName,
        defaultValue,
        required,
        maxlength,
        placeholder,
        error,
        noLabel = false,
        disabled = false,
        value,
        textCount
    }: Props, ref) => {

        return <div className={`textareaField ${className} relative`}>
            {
                !noLabel && <>
                    <span className="formcontentspan">*</span>
                    {
                        label && <label htmlFor={label} className={
                            `${labelClassName}`}>
                            {`${label}\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0\u00A0`}
                        </label>
                    }
                </>
            }
            <span className='absolute left-3 top-8 p-5 text-sm text-gray-500' >{textCount} / 350</span>
            <textarea
                ref={ref}
                disabled={disabled}
                name={name}
                defaultValue={defaultValue}
                placeholder={placeholder}
                value={value}
                className={
                    `field
                    ${inputClassName}`
                }
                maxLength={maxlength}
                id={label}
                onChange={onChange}
                onBlur={onBlur} >
            </textarea>
        </div>;
    }
)
export default TextareaField;