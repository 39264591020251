import { getCounter } from '../../utils/fetchAPI'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface counterState {
   counterlList: string[]
    loading: false
}
const initialState = {
    counterlList: [],
    loading: false
} as counterState
export const fetchCounter = createAsyncThunk(
    'api/counter', async () => {
        const response = await getCounter()
        return response.data;
    })

export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(fetchCounter.pending, (state: { loading: boolean; error: string; }) => {
            state.loading = true
            return state;
        })
        builder.addCase(fetchCounter.fulfilled, (state: { loading: boolean; counterlList: any[] }, action: { payload: any; }) => {
            state.loading = false
            state.counterlList = action.payload.datas;
            return state;
        })

        builder.addCase(fetchCounter.rejected, (state: { loading: boolean; error: any; }, action: { error: { message: any; }; }) => {
            state.loading = false
            state.error = action.error.message
            return state
        })
    }
});

export const counterActions = counterSlice.actions;
export default counterSlice.reducer