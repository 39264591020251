
import ContactLink from '../UI/ContactLink'
import { useEffect, useState } from "react";
import { combinTable } from "utils/dataHandling";
const AboutTeamSection = ({ team, teamContent }: { team: string[], teamContent: string[] }) => {
    const [total, settotal] = useState<any[] | undefined | void>()
    useEffect(() => {
        const main = [...team]
        const content = [...teamContent]
         // 確認主附表資料都有後，關聯主表跟附表
        if (main && content) {
            return settotal(combinTable(main, content))
        }
    }, [team, teamContent])

    return (
        <div className="aboutTeam">
            {total && total.map((val: any) => (
                <div key={val.id} className="container " >
                    <div className="mainVisualImg" data-aos="fade-down"
                        data-aos-duration="1000">
                        <img src={val.image_src} alt={val.title} />
                    </div>
                    <div className="content"  >
                        <span className="aboutMainVisualTitle" data-aos="fade-down"
                            data-aos-duration="1000">{val.title}</span>
                        <div className="contentContainer">
                        {/* 要求暫時先不要顯示成員圖片及姓名 */}
                            {/* <div className="person">
                                <img className="ImgVisual" src={val.photo_src} alt={val.name} />
                                <div className="personContainer">
                                    <div className="personTitleContainer">
                                        <div className="personTitle">
                                            <div className="name">{val.name}</div>
                                            <span>{val.e_name}</span>
                                        </div>
                                        <p className="position">{val.position}</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="jobListContainer">
                                {val.dataList.map((val: any) => (
                                    <p data-aos="fade-up"
                                        data-aos-duration="2000" className="jobList" key={val.id}>{val.content}</p>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            ))}
            <ContactLink className='downContactContainer drop-shadow'></ContactLink>
        </div>
    );
};
export default AboutTeamSection;
