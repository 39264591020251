import { useEffect, useState } from "react";
import { combinTable } from "utils/dataHandling";
import ContactLink from '../UI/ContactLink'

const AboutCopoSection = ({ corp, corpContent }: { corp: string[], corpContent: string[] }) => {
    const [total, settotal] = useState<any[] | undefined | void>()
    useEffect(() => {
        const main = [...corp]
        const content = [...corpContent]
        // 確認主附表資料都有後，關聯主表跟附表
        if (corp && corpContent) {
            return settotal(combinTable(main, content))
        }
    }, [corp, corpContent])
    // 當這筆資料有連結時才會有link顯示，link為非必填
    const pushLink = (val: string) => {
        if (val !== '') {
            const url = val
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('target', '_blank')
            document.body.appendChild(link)
            link.click()
        }
    };
    return (
        <>
            {total && total.map((val) => (
                <div key={val.id} className="aboutCopo" data-aos="fade-down"
                    data-aos-duration="1500">
                    <div className="titleContainer">
                        <span>{val.e_label_name}</span>
                        <h3>{val.label_name}</h3>
                    </div>
                    <div className="contentContainer">
                        {
                            val.dataList.map((val: { id: number; url_link: any, logo_src: string; logo_name: string; }) => (
                                <div key={val.id} onClick={() => pushLink(val.url_link)} className={`imgContainer ${val.url_link ? 'hasUrl' : ''}`}>
                                    <img src={val.logo_src} alt={val.logo_name} />
                                </div>

                            ))
                        }
                    </div>

                </div>
            ))}
            <ContactLink className='downContactContainer'></ContactLink>
        </>
    );
};
export default AboutCopoSection;
