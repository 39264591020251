import { Link } from 'react-router-dom';

const  InternalLink = ({ title, to, className, ...props }: {
  title: string,
  to: string,
  className?: string,
  props?: any
}
) => {
  return (
    <div className='leftLinkContainer'>
      <Link
        className="leftLink"
        to={to}
        {...props}
      >
        {title}
      </Link>
    </div>
  );
};
export default  InternalLink;
